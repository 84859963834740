import * as d3 from "d3";
import * as R from "ramda";

export const LineGraphCurve: d3.CurveFactory = (context) => {
  const _context = context;
  let _line: number,
    _point: number,
    prevX: number,
    prevY: number,
    _t = 1;
  return {
    areaStart: function () {
      _line = 0;
    },
    areaEnd: function () {
      _line = NaN;
    },
    lineStart: function () {
      _point = 0;
    },
    lineEnd: function () {
      if (_line || (_line !== 0 && _point === 2)) _context.closePath();
      if (_line >= 0) (_t = 1 - _t), (_line = 1 - _line);
    },
    point: function (x, y) {
      switch (_point) {
        case 0:
          _point = 1;
          _line ? _context.lineTo(x, y) : _context.moveTo(x, y);
          break;
        case 1:
          this._point = 2; // proceed
        default:
          const delta = Math.min(Math.abs(x - prevX), Math.abs(y - prevY), 1);
          if (y === prevY || delta < 1) {
            _context.lineTo(x, prevY);
            _context.lineTo(x, y);
          } else {
            _context.lineTo(x - delta, prevY);
            _context.quadraticCurveTo(
              x,
              prevY,
              x,
              y < prevY ? prevY - delta : prevY + delta
            );
            _context.lineTo(x, y < prevY ? y + delta : y - delta);
            _context.quadraticCurveTo(x, y, x + delta, y);
          }
          break;
      }
      prevX = x;
      prevY = y;
    },
  };
};

export const PartiallyRoundedRectangleFactory: (
  corners: RectangleCorners
) => d3.CurveFactory = R.curry(
  (corners: RectangleCorners, context: CanvasRenderingContext2D | d3.Path) => {
    // This represents the corner radius, a larger number will be more rounded
    const delta = 5;
    const _context = context;
    let _point: number,
      prevX: number,
      prevY: number,
      startX: number,
      startY: number;
    return {
      areaStart: function () {
        _point = 0;
      },
      areaEnd: R.always(undefined),
      lineStart: R.always(undefined),
      lineEnd: R.always(undefined),
      point: function (x, y) {
        switch (_point) {
          case 0:
            _context.moveTo(x, y);
            _point += 1;
            startX = x;
            startY = y;
            break;
          default:
            if (x > prevX && corners & RectangleCorners.BottomRight) {
              // starts at the bottom left corner, goes right
              _context.lineTo(x - delta, y);
              _context.quadraticCurveTo(x, y, x, y - delta);
            } else if (y < prevY && corners & RectangleCorners.TopRight) {
              // next, goes up
              _context.lineTo(x, y + delta);
              _context.quadraticCurveTo(x, y, x - delta, y);
            } else if (x < prevX) {
              // back left
              if (corners & RectangleCorners.TopLeft) {
                _context.lineTo(x + delta, y);
                _context.quadraticCurveTo(x, y, x, y + delta);
              } else {
                _context.lineTo(x, y);
              }

              // draw the last bit, which goes back to the start and close the path
              if (corners & RectangleCorners.BottomLeft) {
                _context.lineTo(startX, startY - delta);
                _context.quadraticCurveTo(
                  startX,
                  startY,
                  startX + delta,
                  startY
                );
              } else {
                _context.lineTo(x, y);
              }
              _context.closePath();
            } else {
              _context.lineTo(x, y);
            }
        }
        prevX = x;
        prevY = y;
      },
    };
  }
);

export enum RectangleCorners {
  None = 0,
  TopLeft = 1,
  TopRight = 1 << 1,
  BottomLeft = 1 << 2,
  BottomRight = 1 << 3,
  All = ~(~0 << 4),
}

export const drawRoundedRectangle = (
  corners: RectangleCorners
): d3.CurveFactory => PartiallyRoundedRectangleFactory(corners);
