import * as R from "ramda";
import * as d3 from "d3";
import React from "react";

import * as Style from "style";
import {
  D3ComponentProps,
  D3Container,
  D3ContainerProps,
} from "components/D3Container";
import { Transitions } from "./util/transitions";
import { BaseD3Component, EventHandlers, GroupSelection } from "lib/d3";
import { drawLegend } from "./util/legend";
import { SharedGraphProps } from "components/Plan/Report/Builder/type";

const HEIGHT = 320;

const createTooltip = (
  container: SVGSVGElement
): EventHandlers<Data, ScatterPlotComponentProps, Scales> => {
  const Tooltip = d3
    .select(container.parentNode as d3.BaseType)
    .append("div")
    .style("opacity", 0)
    .attr(
      "class",
      "timeline-tooltip absolute bg-white border border-gray-300 rounded text-gray-600 opacity-75 p-3 mb-0 z-10 pointer-events-none"
    );

  const mouseover = function () {
    Tooltip.style("opacity", 1);
  };

  const mouseleave = function () {
    Tooltip.style("opacity", 0);
  };

  const mousemove = function (props: ScatterPlotComponentProps) {
    const {
      extraProps: {
        xAttr,
        xLabel,
        yAttr,
        yLabel,
        xValueFormatter,
        yValueFormatter,
        tooltipFormatters,
        tooltipMeasureDetails,
      },
    } = props;
    return function (e: MouseEvent, d: Data) {
      const [eventX, eventY] = d3.pointer(e);

      const [xVal, yVal] = R.props([xAttr, yAttr], d) as [number, number];

      const color = R.prop("color", d);
      const colorTag = `<p class="text-gray-600">${color}</p>`;

      const html = `
        <span class="text-sm">
          <p>
            <span class="text-gray-600">${R.propOr(
              "No Value",
              "name",
              d
            )}</span>
          </p>
          ${color ? colorTag : ""}
          <p>
            <span class="text-gray-600">${xLabel}: </span>
            <span class="text-gray-800 font-medium">${
              typeof xValueFormatter === "function"
                ? xValueFormatter(xVal)
                : xValueFormatter.format(xVal)
            }
            </span>
          </p>
          ${
            yVal
              ? `<p>
                <span class="text-gray-600">${yLabel}: </span>
                <span class="text-gray-800 font-medium">
                  ${
                    typeof yValueFormatter === "function"
                      ? yValueFormatter(yVal)
                      : yValueFormatter.format(yVal)
                  }
                </span>
              </p>`
              : ""
          }
          ${tooltipMeasureDetails
            .map((m) => {
              const formatter = R.prop(m.key, tooltipFormatters);
              const rawValue = R.prop<number>(m.key, d);
              return `<p>
                <span class="text-gray-600">${m.label}: </span>
                <span class="text-gray-800 font-medium">
                  ${
                    typeof formatter === "function"
                      ? formatter(rawValue)
                      : formatter.format(rawValue)
                  }
                </span>
              </p>`;
            })
            .join("")}
        </span>
      `;

      Tooltip.html(html)
        .style("left", `${eventX + container.parentElement.offsetLeft}px`)
        .style("top", `${eventY + container.parentElement.offsetTop}px`);
    };
  };

  return { mouseover, mousemove, mouseleave };
};

const drawDot = (
  g: d3.Selection<d3.BaseType, Data, SVGGElement, Data[]>,
  {
    props,
    scales,
    ctx,
  }: { props: ScatterPlotComponentProps; scales: Scales; ctx: D3ScatterPlot }
): d3.Selection<d3.BaseType, Data, SVGGElement, Data[]> => {
  const {
    extraProps: { xAttr, yAttr, colorScale, colorAttr, disableTransitions },
  } = props;
  const fillFn = colorScale
    ? R.pipe(R.prop(colorAttr), colorScale)
    : R.always(Style.Color.Chart.CornflowerBlue);
  const { x, y } = scales;
  const { mouseover, mouseleave, mousemove } = ctx.eventHandlers;

  return g
    .on("mousemove", mousemove(props, scales))
    .on("mouseover", function () {
      mouseover();
      d3.select(this).attr("stroke", "black").attr("stroke-width", "1px");
    })
    .on("mouseout", function (e) {
      mouseleave(e);
      d3.select(this).attr("stroke", "").attr("stroke-width", "");
    })
    .attr("fill", (d) => {
      return fillFn(d);
    })
    .attr("r", 5)
    .attr("cx", (d) => x(R.prop<number>(xAttr, d)))
    .attr("cy", (d) => y(R.propOr(0, yAttr, d)))
    .attr("opacity", 0)
    .call((g) => {
      if (!disableTransitions) {
        g.transition(Transitions.Standard()).attr("opacity", 1);
      } else {
        g.attr("opacity", 1);
      }
    });
};

const drawDots = (
  g: GroupSelection<Data[]>,
  {
    props,
    scales,
    ctx,
  }: { props: ScatterPlotComponentProps; scales: Scales; ctx: D3ScatterPlot }
) => {
  const {
    data,
    extraProps: { xAttr, yAttr },
  } = props;

  const dots = g
    .selectAll("circle")
    .data(
      data.filter(
        (d) =>
          !Number.isNaN(R.prop(xAttr, d)) && !Number.isNaN(R.prop(yAttr, d))
      )
    );

  dots.join(
    (enter) => drawDot(enter.append("circle"), { props, scales, ctx }),
    (update) => drawDot(update, { props, scales, ctx }),
    (exit) => exit.remove()
  );
};

type Data = Record<string, unknown>;

interface D3Props {
  colorScale: d3.ScaleOrdinal<string, string>;
  colorAttr: string;
  xAttr: string;
  yAttr: string;
  xLabel: string;
  yLabel: string;
  xTickFormatter: (value: number) => string;
  yTickFormatter: (value: number) => string;
  xValueFormatter: ((value: number) => string) | Intl.NumberFormat;
  yValueFormatter: ((value: number) => string) | Intl.NumberFormat;
  title: string;
  showLegend?: boolean;
  disableTransitions?: boolean;
  tooltipMeasureDetails: SharedGraphProps["tooltipMeasureDetails"];
  tooltipFormatters: SharedGraphProps["tooltipFormatters"];
}

interface Scales {
  x: d3.ScaleLinear<number, number>;
  y: d3.ScaleLinear<number, number>;
  legend: d3.ScaleOrdinal<string, string>;
}

export type ScatterPlotComponentProps = D3ComponentProps<Data[], D3Props>;
export type ScatterPlotProps = Omit<
  D3ContainerProps<Data[], Omit<D3Props, "computed">>,
  "width" | "height"
>;
export type ScatterPlotData = Data;

export class D3ScatterPlot extends BaseD3Component<
  Data[],
  ScatterPlotComponentProps,
  Scales
> {
  width: number;
  height: number;
  eventHandlers: EventHandlers<Data, ScatterPlotComponentProps, Scales>;

  constructor(container: HTMLElement, props: ScatterPlotComponentProps) {
    super(container, props);
    this.eventHandlers = createTooltip(this.svg.node());
    this.height = this.margin.top + this.margin.bottom + HEIGHT;
    this.width = props.width;

    this.init();
  }

  getBaseMargin() {
    return {
      top: 30,
      right: this.props.extraProps.showLegend ? 200 : 100,
      bottom: 50,
      left: 100,
    };
  }

  init() {
    const {
      extraProps: { title, xLabel, yLabel },
    } = this.props;
    Style.D3Text.Title(this.svg.select("g.x.axis"));
    Style.D3Text.Title(this.svg.select("g.y.axis"));

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `title`)
        .attr("text-anchor", "middle")
        .attr("x", this.width / 2)
        .attr("y", 15)
        .text(title)
    );

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `x label`)
        .attr("fill", "currentColor")
        .attr("text-anchor", "middle")
        .attr("x", this.width / 2)
        .attr("y", this.height)
        .text(xLabel)
    );

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `y label`)
        .attr("fill", "currentColor")
        .attr("text-anchor", "middle")
        .attr("transform", `translate(20, ${this.height / 2}) rotate(-90)`)
        .text(yLabel)
    );

    Style.D3Text.Title(this.svg.select(".legend").attr("fill", "currentColor"));
  }

  getGraphAxes(): {
    [key: string]: (
      container: GroupSelection<Data[]>,
      {
        props,
        scales,
        ctx,
      }: {
        props: ScatterPlotComponentProps;
        scales: Scales;
        ctx: D3ScatterPlot;
      }
    ) => void;
  } {
    return {
      x: (
        g,
        {
          props: {
            extraProps: { xTickFormatter },
          },
          scales: { x },
        }
      ) =>
        g
          .attr("transform", `translate(0,${this.height - this.margin.bottom})`)
          .call(
            d3.axisBottom(x).ticks(5).tickFormat(xTickFormatter).tickSize(0)
          )
          .call((g) => g.selectAll(".domain").remove()),
      y: (
        g,
        {
          props: {
            extraProps: { yLabel, yTickFormatter },
          },
          scales: { y },
        }
      ) =>
        Style.D3.Layout.Line(
          g
            .attr("transform", `translate(${this.margin.left},0)`)
            .call(
              d3
                .axisRight(y)
                .ticks(5)
                .tickFormat(yTickFormatter)
                .tickSize(this.width - this.margin.right - this.margin.left)
            )
            .call((g) => g.selectAll(".domain").remove())
            .call((g) =>
              Style.D3Text.Title(g.selectAll(".tick text")).attr("x", "-40")
            )
            // Remove the tick labels if there is no traditional Y axis (number line scenario)
            .call((g) => !yLabel && g.selectAll(".tick text").remove())
        ),
    };
  }

  createScales(props: ScatterPlotComponentProps): Scales {
    const {
      data,
      extraProps: { xAttr, yAttr, colorScale },
    } = props;

    const [minX, maxX] = d3.extent(data, R.prop<number>(xAttr)) as [
      number,
      number
    ];
    const [minY, maxY] = d3.extent(data, R.prop<number>(yAttr)) as [
      number,
      number
    ];

    return {
      x: d3
        .scaleLinear()
        .domain([minX * 0.98, maxX * 1.02])
        .range([this.margin.left, this.width - this.margin.right]),
      y: d3
        .scaleLinear()
        .domain([(minY || 0) * 0.98, (maxY || 0) * 1.02])
        .range([this.height - this.margin.bottom, this.margin.top])
        .nice(),
      legend: colorScale,
    };
  }

  getGraphElements(): {
    [key: string]: (
      container: GroupSelection<Data[]>,
      {
        props,
        scales,
        ctx,
      }: {
        props: ScatterPlotComponentProps;
        scales: Scales;
        ctx: BaseD3Component<Data[], ScatterPlotComponentProps, Scales>;
      }
    ) => void;
  } {
    return {
      dots: drawDots,
      legend: drawLegend({
        gutter: { left: 40, right: 20 },
        position: ({ gutter, ctx }) =>
          `translate(${ctx.width - ctx.margin.right + gutter.left}, ${
            ctx.margin.top
          })`,
      }),
    };
  }

  beforeUpdate() {
    const {
      width,
      extraProps: { title, xLabel, yLabel },
    } = this.props;

    this.width = width;
    this.svg
      .attr("viewBox", `0,0,${this.width},${this.height + 10}`)
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    this.svg
      .selectAll("text.title")
      .attr("x", this.width / 2)
      .attr("y", 15)
      .text(title);

    this.svg
      .selectAll("text.x.label")
      .attr("x", this.width / 2)
      .attr("y", this.height)
      .text(xLabel);

    this.svg
      .selectAll("text.y.label")
      .attr(
        "transform",
        `translate(20,${
          this.margin.top +
          (this.height - this.margin.top - this.margin.bottom) / 2
        }) rotate(-90)`
      )
      .text(yLabel);
  }
}

const createChart = (ref: HTMLElement, props: ScatterPlotComponentProps) => {
  return new D3ScatterPlot(ref, props);
};

export const ScatterPlot: React.FC<ScatterPlotProps> = (
  props
): React.ReactElement => {
  return <D3Container D3ComponentFactory={createChart} {...props} />;
};
