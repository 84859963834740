import {
  Data,
  Grouped,
  PaybandChartComponentProps,
  PaybandFnProps,
  TenureCategory,
} from "./types";

import * as R from "ramda";

import { ICON_BUFFER } from "./constant";
import { toHourlyValues } from "@milio/lib/util/math/util";
import { createFormatter } from "lib/numberFormatter";
import { isAllHourly } from "../util/hourly";
import { HydratedEmployee } from ".generated/models";

export const isDataGroup = (d: Data | Data[]): d is Data[] => Array.isArray(d);
export const getIconBuffer = (props: PaybandChartComponentProps): number => {
  return props.extraProps.hideToggleControls ? 0 : ICON_BUFFER;
};
export const getGroupFontSize = (d: Data | Grouped) => {
  if (!isGrouped(d)) return "12px";

  const len = d.size.toString().length;

  if (len <= 2) {
    return "12px";
  } else if (len <= 3) {
    return "10px";
  }

  return "8px";
};

export const derivePaybandsFromData = ({ y, min, max, yKey }: PaybandFnProps) =>
  R.uniqBy(
    R.applySpec({
      key: yKey,
      y: typeof y === "function" ? y : R.prop(y),
      min: R.prop(min),
      max: R.prop(max),
    })
  );

export const toHourlyBenchmarks = R.pipe(
  R.over<any, number>(R.lensProp("external_benchmark_25"), toHourlyValues),
  R.over<any, number>(R.lensProp("external_benchmark_50"), toHourlyValues),
  R.over<any, number>(R.lensProp("external_benchmark_75"), toHourlyValues),
  R.over<any, number>(R.lensProp("external_benchmark_25_base"), toHourlyValues),
  R.over<any, number>(R.lensProp("external_benchmark_50_base"), toHourlyValues),
  R.over<any, number>(R.lensProp("external_benchmark_75_base"), toHourlyValues)
);

export function getIsHourly(props: PaybandChartComponentProps) {
  const {
    data,
    extraProps: { useHourly },
  } = props;
  const hourlyIsDefined = !R.isNil(useHourly);
  const isHourly = hourlyIsDefined ? useHourly : isAllHourly(data);

  return isHourly;
}

export const deriveBenchmarksFromData = (
  accessor: (obj: any) => Record<string, unknown>,
  props: PaybandChartComponentProps
) => {
  const isHourly = getIsHourly(props);
  const {
    data,
    extraProps: { yAttr, yKeyAttr },
  } = props;
  const getYKey = getYValue(yKeyAttr || yAttr);
  return R.pipe(
    R.uniqBy(getYKey),
    R.map(accessor),
    R.reject(R.where({ external_benchmark_25: R.isNil })),
    R.map(isHourly ? toHourlyBenchmarks : R.identity)
  )(data);
};

export const getGroupOffset = (d: Data | Grouped) => {
  if (!isGrouped(d)) return 4;

  const len = d.size.toString().length;

  if (len <= 2) {
    return 4;
  } else if (len <= 3) {
    return 3;
  }

  return 2;
};

export const isGrouped = (d: Data | Grouped): d is Grouped => "size" in d;

export const getPaybandColor = (
  val: Data | Data[],
  props: PaybandChartComponentProps
): string => {
  const { colorAttr } = props.extraProps;
  if (colorAttr === "label") {
    if (
      R.any(
        R.propEq(colorAttr, "proposed") as unknown as (d: Data) => boolean,
        Array.isArray(val) ? val : [val]
      )
    ) {
      return "proposed";
    }
    if (
      R.any(
        R.propEq(colorAttr, "current") as unknown as (d: Data) => boolean,
        Array.isArray(val) ? val : [val]
      )
    ) {
      return "current";
    }
    return "other";
  }

  if (!isDataGroup(val)) return R.prop(colorAttr, val);

  return R.length(R.uniqBy(R.prop(colorAttr), val)) === 1
    ? R.path([0, colorAttr], val)
    : "group";
};

export const getTenureColor = (
  val: Data | Data[],
  props: PaybandChartComponentProps
): string => {
  const {
    data,
    extraProps: { colorAttr, current },
  } = props;
  const reference = data.find((e: Data) => e.employee_id === current);
  if (!reference) return;

  if (!isDataGroup(val)) {
    if (R.prop(colorAttr, val)) {
      return R.prop(colorAttr, val);
    }
    if (val.employee_id === reference.employee_id) {
      return val.name;
    }

    if (val.pay > reference.pay) {
      return TenureCategory.More;
    }
    if (val.pay < reference.pay) {
      return TenureCategory.Less;
    }
    return TenureCategory.Same;
  }

  if (R.find(R.whereEq({ employee_id: reference.employee_id }), val)) {
    return R.prop("name", reference);
  }

  return R.length(R.uniqBy(R.flip(R.curry(getTenureColor))(props), val)) === 1
    ? getTenureColor(val[0], props)
    : TenureCategory.Multiple;
};

export const getFormatter = (
  currency: string,
  base_currency: string,
  attr: keyof HydratedEmployee
): Intl.NumberFormat => {
  const local = createFormatter({ style: "currency", currency });
  const base = createFormatter({ style: "currency", currency: base_currency });
  const localHourly = createFormatter({
    style: "currency",
    currency,
    digits: 2,
  });
  const baseHourly = createFormatter({
    style: "currency",
    currency: base_currency,
    digits: 2,
  });

  switch (attr) {
    case "pay":
      return local;
    case "pay_hourly":
      return localHourly;
    case "pay_base":
      return base;
    case "pay_base_hourly":
      return baseHourly;
    default:
      return createFormatter({ style: "decimal" });
  }
};

export const getYValue = R.curry(
  <T>(yAttr: string | ((val: T) => string), d: T) => {
    if (typeof yAttr === "function") {
      return yAttr(d);
    }
    return R.prop(yAttr, d);
  }
);
