import React from "react";
import * as R from "ramda";
import * as pluralize from "pluralize";

import { Paragraph } from "components/library";
import { createFormatter } from "lib/numberFormatter";
import { DECIMAL_PRECISION } from "util/format";
import { Grouped } from "./types";

export interface GroupTooltipProps {
  group: Grouped;
  currency: string;
  payAttr: string;
}

export const GroupTooltip: React.FC<GroupTooltipProps> = ({
  currency,
  group,
  payAttr,
}) => {
  const formatter = React.useMemo(() => {
    return payAttr !== "role_tenure"
      ? createFormatter({
          style: "currency",
          currency,
          digits: DECIMAL_PRECISION,
        })
      : createFormatter({ style: "decimal", digits: DECIMAL_PRECISION });
  }, [group]);

  const label = React.useMemo(() => {
    return payAttr === "role_tenure" ? "Tenure (avg)" : "Base Comp (avg)";
  }, [group]);

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
        <div className="grid">
          <span className="absolute inset-0" aria-hidden="true" />
          <Paragraph>
            {group.size} {pluralize("employee", group.size)}
          </Paragraph>
          <Paragraph>
            {label}: {formatter.format(R.prop(payAttr, group))}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
