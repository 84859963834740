import * as R from "ramda";
import { Decimal } from "decimal.js";

/**
 * Determine a percent increase.
 */
export function percentIncrease({
  start,
  end,
}: {
  start: number;
  end: number;
}): number {
  return (end - start) / Math.abs(start);
}

/**
 * Determine the percent of a number.
 */
export function percentOf({
  value,
  percent,
}: {
  value: number;
  percent: number;
}): number {
  return value * percent;
}

const toDecimal = (d: number) => new Decimal(d || 0);
const toNumber = (d: Decimal) => d.toNumber();
const annualize = (d: Decimal) => d.mul(2080);
const deannualize = (d: Decimal) => d.div(2080);
const truncate = (d: Decimal) => d.toDecimalPlaces(2, Decimal.ROUND_UP);
export const toAnnualizedValues = R.pipe(toDecimal, annualize, toNumber);
export const toHourlyValues = R.pipe(toDecimal, deannualize, toNumber);
export const toTwoDecimalPlaces = R.pipe(toDecimal, truncate, toNumber);
