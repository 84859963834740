/** Tools for determining and drawing rectangular regions on the graph. */
import * as R from "ramda";
import * as d3 from "d3";

import { GroupSelection } from "lib/d3";
import { Data, Meta, PaybandChartComponentProps } from "../types";
import { getPaybandMaxPosition, getPaybandMinPosition } from "./detail";
import { D3RangeAdheranceChart } from "..";
import { RegionRectangle } from "../data/regions";

export interface RegionParams {
  props: PaybandChartComponentProps;
  scales: {
    x: d3.ScaleLinear<number, number>;
    y: d3.ScaleLinear<number, number>;
  };
  ctx: D3RangeAdheranceChart;
}

export const drawRegions = (
  groups: GroupSelection<unknown>,
  params: RegionParams
) => {
  const {
    props,
    scales: { x, y },
  } = params;
  const { regions } = props.extraProps.computed;
  const [yMin, yMax] = y.domain();
  const [xMin, xMax] = x.domain();

  groups.each((datum: Meta, i: number, nodes: SVGGElement[]) => {
    const rectangleParams = R.mergeDeepRight(params, {
      props: {
        extraProps: {
          computed: { paybandMinAttr: "x1", paybandMaxAttr: "x2" },
        },
      },
    });
    const fixLeftBoundary = (value: number, rect: RegionRectangle) => {
      if (rect.x1 === 0 || rect.x1 === -Infinity) return x(xMin);
      return value;
    };
    const fixRightBoundary = (value: number, rect: RegionRectangle) => {
      if (rect.x2 === Infinity) return x(xMax);
      return value;
    };
    const xLeftPosition = getPaybandMinPosition({
      data: datum.data,
      params: rectangleParams,
    });
    const xRightPosition = getPaybandMaxPosition({
      data: datum.data,
      params: rectangleParams,
    });
    const node = d3.select(nodes[i]);
    node
      .selectAll("rect.region")
      .data(R.propOr([], datum.key, regions) as RegionRectangle[])
      .join("rect")
      .attr("class", "region pointer-events-none")
      .style("opacity", 0)
      .attr("x", (d: RegionRectangle) => {
        const xLeft = xLeftPosition(d as unknown as Data);
        return fixLeftBoundary(xLeft, d);
      })
      .attr("width", (d: RegionRectangle) => {
        const xLeft = xLeftPosition(d as unknown as Data);
        const xRight = xRightPosition(d as unknown as Data);
        return fixRightBoundary(xRight, d) - fixLeftBoundary(xLeft, d);
      })
      .attr("y", (d: RegionRectangle) => {
        const yTop = d.y2 === Infinity ? yMax : d.y2;
        return y(yTop);
      })
      .attr("height", (d: RegionRectangle) => {
        const yTop = d.y2 === Infinity ? yMax : d.y2;
        const yBottom = d.y1 < yMin ? yMin : d.y1;
        return y(yBottom) - y(yTop);
      });
  });
};
