import type { Data, Grouped, Params } from "../types";
import type { Benchmark } from "../data/regions";

import * as R from "ramda";

import * as Style from "style";
import { BENCHMARK_LABELS, SUBKEY_SEPARATOR } from "../constant";
import { GroupSelection } from "lib/d3";
import { Transitions } from "components/graphs/util/transitions";
import { D3LabelledBand } from "data/d3";
import { getBenchmarkFields } from "../props";

const updateTick = R.curry(
  (
    { scales }: Params,
    g: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>
  ) => {
    const { x } = scales;
    return g
      .attr("fill", Style.Color.Layout.Gray)
      .attr("rx", 2)
      .attr("x", (d) => x(d[1]))
      .attr("y", -12)
      .attr("width", 4)
      .attr("height", 24);
  }
);

const updateText = R.curry(
  (
    { scales }: Params,
    g: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>
  ) => {
    const { x } = scales;

    return Style.D3Text.Paragraph(
      g
        .attr("fill", Style.Color.Layout.Gray)
        .attr("x", (d) => x(d[1]) - 12)
        .attr("y", (d) => {
          if (
            d[0] === "external_benchmark_50" ||
            d[0] === "external_benchmark_50_base"
          )
            return 24;
          return -16;
        })
        .text((d) => R.prop(d[0], BENCHMARK_LABELS))
    );
  }
);

const updateTitle = R.curry(
  (
    { props }: Params,
    g: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>
  ) => {
    const { formatter } = props.extraProps.computed;
    return g.attr(
      "text",
      (d) =>
        `${R.prop(d[0], BENCHMARK_LABELS)} Percentile: ${formatter.format(
          d[1]
        )}`
    );
  }
);

export const drawBenchmarks = (
  g: d3.Selection<d3.BaseType, Benchmark, d3.BaseType, Grouped>,
  params: Params
) => {
  const { props, scales } = params;

  const { y } = scales;
  const { getYKey } = props.extraProps.computed;
  const benchmarkFields = getBenchmarkFields(props);

  const updateTitleWithParams = updateTitle(params);
  const updateTickWithParams = updateTick(params);
  const updateTextWithParams = updateText(params);

  const yScale = y as D3LabelledBand;

  g.attr("class", "benchmark-group")
    .attr("data-key", (d: Data) => getYKey(d))
    .attr("transform", (d: Data) => {
      const yValue = R.or(getYKey(d), R.prop("y", d));
      return `translate(0, ${
        yScale(yValue + SUBKEY_SEPARATOR + "0") + yScale.bandwidth() / 2
      })`;
    })
    .attr("opacity", 1)
    .attr("pointer-events", "none")
    .selectAll(".benchmark")
    .data((d) => R.zip(benchmarkFields, R.props(benchmarkFields, d as any)))
    .join(
      (enter) => {
        const g = enter.append("g").attr("class", "benchmark");
        g.append("rect").attr("class", "tick").call(updateTickWithParams);
        g.append("text").call(updateTextWithParams);
        g.append("title").call(updateTitleWithParams);
        return g;
      },
      (update) => {
        update.selectAll("rect.tick").call(updateTickWithParams);
        update.selectAll("text").call(updateTextWithParams);
        update.selectAll("title").call(updateTitleWithParams);
        return update;
      },
      (exit) => exit.remove()
    );
};

export const drawBenchmarkGroups = (
  g: GroupSelection<Grouped>,
  { props, scales, ctx }: Params
) => {
  const { benchmarks } = props.extraProps.computed;

  const benchmarkFields = getBenchmarkFields(props);

  const params = { props, scales, ctx };

  g.selectAll("g.benchmark-group")
    .data(
      benchmarks || [],
      R.props(benchmarkFields) as unknown as d3.ValueFn<
        d3.BaseType,
        unknown,
        d3.KeyType
      >
    )
    .join(
      (enter) => enter.append("g").call((g) => drawBenchmarks(g, params)),
      (update) => update.call((g) => drawBenchmarks(g, params)),
      (exit) => {
        return exit
          .transition(Transitions.Standard())
          .attr("opacity", 0)
          .remove();
      }
    );
};
