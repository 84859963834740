import { EXPORT_LEGEND_LIMIT } from "components/Plan/Report/Builder/constant";
import { legendColor } from "d3-svg-legend";
import { BaseD3Component, GroupSelection } from "lib/d3";
import * as R from "ramda";

interface HasShowLegendProp {
  extraProps: {
    showLegend: boolean;
  };
}

interface Gutter {
  left: number;
  right: number;
}

interface LegendOptions<C> {
  gutter: Gutter;
  position: (props: { gutter: Gutter; ctx: C }) => string;
}

const limitLegendItems = (legendScale: d3.ScaleOrdinal<string, string>) => {
  const domain = legendScale.domain();
  return legendScale.domain(R.take(EXPORT_LEGEND_LIMIT, domain));
};

export const drawLegend = R.curry(
  <D, P, S>(
    legendOptions: LegendOptions<BaseD3Component<D, P, S>>,
    g: GroupSelection<D[]>,
    {
      props,
      scales,
      ctx,
    }: { props: HasShowLegendProp; scales: S; ctx: BaseD3Component<D, P, S> }
  ) => {
    if (!props.extraProps.showLegend) {
      return;
    }

    const legend = g.attr(
      "transform",
      legendOptions.position({ gutter: legendOptions.gutter, ctx })
    );
    // ignoring as we have to work around this library
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (legend as any).call(
      legendColor()
        .shape("circle")
        .shapeRadius(5)
        .shapePadding(10)
        .labelWrap(
          ctx.margin.right -
            legendOptions.gutter.left -
            legendOptions.gutter.right
        )
        .scale(
          limitLegendItems(
            R.prop<d3.ScaleOrdinal<string, string>>("legend", scales)
          )
        )
    );
  }
);
