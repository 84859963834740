import React from "react";
import * as R from "ramda";

import { Paragraph, SubTitle } from "components/library";
import { createFormatter } from "lib/numberFormatter";
import { DECIMAL_PRECISION } from "util/format";
import { BENCHMARK_LABELS } from "./constant";
import { displayDate } from "@milio/lib/util/date";
import { PercentFormat } from "@milio/lib/util/percent/constant";
import { percentize } from "@milio/lib/util/percent/util";

export interface EmployeeBenchmark {
  type?:
    | "external_benchmark_25"
    | "external_benchmark_50"
    | "external_benchmark_75";
  value: number;
  currency: string;
  external_benchmark_survey_date: string;
  external_benchmark_aging_factor?: number;
  external_benchmark_original_survey_date?: string;
}

export interface BenchmarkTooltipProps {
  benchmark: EmployeeBenchmark;
}

export const BenchmarkTooltip: React.FC<BenchmarkTooltipProps> = ({
  benchmark,
}) => {
  const currencyFormatter = React.useMemo(() => {
    return createFormatter({
      style: "currency",
      currency: benchmark.currency,
      digits: DECIMAL_PRECISION,
    });
  }, [benchmark]);

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
        <div className="min-w-0 flex-1">
          <a href="#" className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900">
              {BENCHMARK_LABELS[benchmark.type]} Percentile
            </p>
            {R.has("external_benchmark_original_survey_date", benchmark) && (
              <div>
                <Paragraph>
                  aged,{" "}
                  {percentize(benchmark.external_benchmark_aging_factor, {
                    input: PercentFormat.Decimal,
                    output: PercentFormat.Percentage,
                    precision: 2,
                  })}
                  % per month (survey:{" "}
                  {displayDate(
                    benchmark.external_benchmark_original_survey_date
                  )}
                  )
                </Paragraph>
              </div>
            )}
            <SubTitle>
              Amount: {currencyFormatter.format(benchmark.value)}
            </SubTitle>
          </a>
        </div>
      </div>
    </div>
  );
};
