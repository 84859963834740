import type { Data, Grouped, GroupedSelection, Meta, Params } from "../types";

import * as d3 from "d3";
import * as R from "ramda";

import { removeSelection } from "./common";
import { getGroupFontSize, getGroupOffset, isGrouped } from "../util";

import { GroupSelection } from "lib/d3";
import { Transitions } from "components/graphs/util/transitions";

const drawGroup = (
  group: d3.Selection<d3.BaseType, Meta, d3.BaseType, unknown>,
  params: Params
): d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, unknown> => {
  return group
    .selectAll("circle")
    .data((d: Meta) => d.grouped)
    .join(
      (enter) => drawNewDot(enter, params) as GroupedSelection,
      (update) => updateDot(update, params) as GroupedSelection,
      (exit) => removeSelection(exit)
    );
};

export const drawDotGroups = (
  g: GroupSelection<unknown>,
  { props, scales, ctx }: Params
) => {
  const {
    groupedData: data,
    expandedState,
    payAttr,
  } = props.extraProps.computed;

  const noData = R.pipe(
    R.map(R.prop("data")),
    R.flatten,
    R.uniqBy(R.prop(payAttr)),
    R.pluck(payAttr),
    R.all(R.isNil)
  )(data);

  const dotGroups = g.selectAll("g").data(noData ? [] : data);

  dotGroups.join(
    (enter) => {
      const group = enter
        .filter((d) => !expandedState[d.key])
        .append("g")
        .attr("class", "group pointer-events-none")
        .attr("data-group", R.prop("key"));
      drawGroup(group, { props, scales, ctx });
      drawLabels(group, { props, scales, ctx });
      return group;
    },
    (update) => {
      const group = update.attr("data-group", R.prop("key"));
      drawGroup(group, { props, scales, ctx });
      drawLabels(group, { props, scales, ctx });
      if (!props.extraProps.disableTransitions) {
        group.call((g) => g.transition(Transitions.Cubic()));
      }
      return group;
    },
    (exit) => exit.remove()
  );
};

export const drawCircleCommon = (
  e: d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, unknown>,
  { props, scales }: Params
): d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, unknown> => {
  const { colorScale, getGroupColor } = props.extraProps;
  const { payAttr } = props.extraProps.computed;
  const { x, y } = scales;
  if (!props.extraProps.disableTransitions) {
    e.call((g) => g.transition(Transitions.Cubic()));
  }
  return e
    .attr("cx", (d: Data | Grouped) => {
      return x(d[payAttr]);
    })
    .attr("cy", (d: Data | Grouped) => {
      return y(d.subkey) + y.bandwidth() / 2;
    })
    .attr("r", 10)
    .attr("opacity", 1)
    .attr("fill", (d: Data | Grouped) => {
      return colorScale(getGroupColor(d as Data, props));
    });
};

const drawNewDot = (
  enter: d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, unknown>,
  params: Params
): d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, undefined> => {
  return drawCircleCommon(enter.append("circle"), params);
};

const updateDot = (
  update: d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, unknown>,
  params: Params
): d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, unknown> => {
  return drawCircleCommon(update, params);
};

const drawGroupLabelCommon = (
  selection: d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, Meta>,
  { props, scales }: Params
): d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, Meta> => {
  const { payAttr } = props.extraProps.computed;
  const { x, y } = scales;
  return selection
    .attr("text-anchor", "middle")
    .attr("color", "white")
    .attr("font-size", getGroupFontSize)
    .attr("x", (d: Data | Grouped) => x(d[payAttr]))
    .attr("y", (d) => y(d.subkey) + y.bandwidth() / 2 + getGroupOffset(d))
    .text((d) => (isGrouped(d) ? (d.size > 1 && d.size) || "" : ""));
};

const drawNewLabel = (
  enter: d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, Meta>,
  params: Params
): d3.Selection<d3.BaseType, Data | Grouped, d3.BaseType, Meta> => {
  return drawGroupLabelCommon(enter.append("text"), params);
};

const drawLabels = (
  group: d3.Selection<d3.BaseType, Meta, d3.BaseType, unknown>,
  params: Params
) => {
  group
    .selectAll("text")
    .data((d: Meta) => d.grouped)
    .join(
      (enter) => drawNewLabel(enter, params) as GroupedSelection,
      (update) => drawGroupLabelCommon(update, params) as GroupedSelection,
      (exit) => removeSelection(exit)
    );
};
