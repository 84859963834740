import {
  BenchmarkType,
  Data,
  IconData,
  Margin,
  Meta,
  Params,
  PaybandChartComponentProps,
  RangeAdheranceChartEvent,
  RangeAdheranceChartProps,
  Scales,
} from "./types";

import React from "react";
import * as d3 from "d3";
import * as R from "ramda";

import * as Style from "style";
import { BaseD3Component, EventHandlers, GroupSelection } from "lib/d3";
import { D3Container } from "components/D3Container";
import { ExpansionState } from "components/library";
import { drawLegend } from "../util/legend";
import { format } from "../util";
import { wrap } from "../util/wrap";
import { getComputedProps } from "./props";
import {
  BAR_HEIGHT,
  SCATTER_PLOT_MULTIPLIER,
  SUBKEY_SEPARATOR,
  TICKS,
} from "./constant";
import { getIconBuffer, getYValue } from "./util";
import { drawCandlesticks } from "./drawing/candlesticks";
import { drawBenchmarkGroups } from "./drawing/benchmarks";
import { drawIcons } from "./drawing/icons";
import { drawDotGroups } from "./drawing/dots";
import { drawGroupDetail } from "./drawing/detail";
import { createEmployeeTooltip } from "./tooltip";
import { D3LabelledBand } from "data/d3";
import { drawOverlay } from "./drawing/overlay";
import { HydratedPlan } from ".generated/models";

export class D3RangeAdheranceChart extends BaseD3Component<
  Data[],
  PaybandChartComponentProps,
  Scales
> {
  width: number;
  height: number;
  dot_size: number;

  onIconClick: (e: MouseEvent, d: IconData) => void;
  expandedState: { [key: string]: boolean };
  eventHandlers: EventHandlers<
    Meta,
    PaybandChartComponentProps,
    { x: d3.ScaleLinear<number, number>; y: d3.ScaleLinear<number, number> }
  >;

  constructor(container: HTMLElement, props: PaybandChartComponentProps) {
    super(container, props);
    this.width = props.width;
    this.eventHandlers = createEmployeeTooltip(this.svg.node());
    this.init();
  }

  init(): void {
    const {
      data,
      extraProps: { xLabel },
    } = this.props;

    this.expandedState = {};

    this.props.extraProps.computed = getComputedProps({
      props: this.props,
      ctx: this,
    });

    const { getYKey } = this.props.extraProps.computed;

    const jobTitles = R.keys(R.groupBy(getYKey, data));
    this.height =
      this.margin.top +
      this.margin.bottom +
      (R.propOr(BAR_HEIGHT, "barHeight", this.props.extraProps) as number) *
        jobTitles.length;

    Style.D3Text.Title(this.svg.selectAll(".axis"));

    Style.D3Text.Title(
      this.svg.select(".candlesticks").attr("fill", "currentColor")
    );
    Style.D3Text.Title(
      this.svg.select(".benchmarks").attr("fill", "currentColor")
    );
    Style.D3Text.Title(this.svg.select(".groups").attr("fill", "currentColor"));
    Style.D3Text.Title(this.svg.select(".detail").attr("fill", "currentColor"));
    Style.D3Text.Title(this.svg.select(".legend").attr("fill", "currentColor"));

    Style.D3Layout.Line(this.svg.select(".icons"));

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `x label`)
        .attr("text-anchor", "middle")
        .attr("fill", "currentColor")
        .attr("x", this.width / 2)
        .attr("y", this.height - 6)
        .text(xLabel)
    );
  }

  getGraphAxes(): {
    [key: string]: (
      g: GroupSelection<Data[]>,
      { props, scales }: Params
    ) => void;
  } {
    return {
      xTop: (g, { props, scales }) => {
        const { x } = scales;
        const { formatter } = props.extraProps.computed;

        g.attr("transform", `translate(0,${this.margin.top})`)
          .call(
            d3.axisTop(x).ticks(TICKS).tickFormat(format(formatter)).tickSize(0)
          )
          .call((g) => g.selectAll(".domain").remove());
      },
      y: (g, { props, scales }) => {
        const { y } = scales;
        const { renderCompact, yHighlight, highlightColor } = props.extraProps;
        const { paybands, getYKey, getYValue } = props.extraProps.computed;
        const jobTitles = R.indexBy(getYKey, paybands);
        Style.D3.Layout.Line(
          g
            .attr("transform", `translate(${this.margin.left},0)`)
            .call(
              d3
                .axisRight(y as D3LabelledBand)
                .tickSize(this.width - this.margin.right - this.margin.left)
                .tickFormat((d: string) => {
                  const key = d.endsWith(`${SUBKEY_SEPARATOR}0`)
                    ? d.substring(0, d.indexOf(SUBKEY_SEPARATOR))
                    : d;
                  const label = getYValue(R.prop(key, jobTitles));
                  return label;
                })
            )
            .call((g) =>
              Style.D3Text.Title(g.selectAll(".tick text"))
                .filter((d: string) => {
                  return (
                    yHighlight.indexOf(
                      d.substring(0, d.indexOf(SUBKEY_SEPARATOR))
                    ) > -1
                  );
                })
                .attr("fill", highlightColor)
            )
            // highlight ticks
            .call((g) =>
              g
                .selectAll(".tick text")
                .filter((d: string) => {
                  return (
                    yHighlight.indexOf(
                      d.substring(0, d.indexOf(SUBKEY_SEPARATOR))
                    ) === -1
                  );
                })
                .attr("fill", "currentColor")
            )
            // remove the ticklines from subrows
            .call((g) =>
              g
                .selectAll(".tick")
                .filter((d: string) => {
                  return (
                    d.indexOf(SUBKEY_SEPARATOR) > -1 &&
                    Number(
                      d.substring(
                        d.indexOf(SUBKEY_SEPARATOR) + SUBKEY_SEPARATOR.length,
                        d.length
                      )
                    ) > 0
                  );
                })
                .remove()
            )
            .call((g) => {
              if (renderCompact) {
                g.selectAll(".tick text").remove();
              }
            })
            .call((g) => {
              g.selectAll(".tick text")
                .attr("x", `-${this.margin.left - getIconBuffer(this.props)}`)
                .each(wrap(this.margin.left - getIconBuffer(this.props), 5));
              g.selectAll(".tick text")
                .on("click", (_e, d: string) => {
                  const plan: HydratedPlan =
                    jobTitles[d.substring(0, d.indexOf(SUBKEY_SEPARATOR))];

                  this.emitter.emit(RangeAdheranceChartEvent.GoToPlan, plan);
                })
                .attr("class", "cursor-pointer");
            })
            .call((g) => g.selectAll(".domain").remove())
        );
      },
    };
  }

  getGraphElements() {
    return {
      candlesticks: drawCandlesticks,
      benchmarkTicks: drawBenchmarkGroups,
      icons: drawIcons,
      groups: drawDotGroups,
      detail: drawGroupDetail,
      overlay: drawOverlay,
      legend: drawLegend({
        gutter: { left: 40, right: 20 },
        position: ({ gutter, ctx }) =>
          `translate(${ctx.width - ctx.margin.right + gutter.left}, ${
            ctx.margin.top
          })`,
      }),
    };
  }

  createScales(): Scales {
    const { data } = this.props;
    const { benchmarkType } = this.props.extraProps;
    const {
      benchmarks,
      groupedData,
      payAttr,
      paybandMinAttr,
      paybandMaxAttr,
      paybands,
    } = this.props.extraProps.computed;
    const [actualMin, actualMax] = d3.extent(data, (d) => d[payAttr]);
    const paybandMin = d3.min(paybands, (d) => d[paybandMinAttr]);
    const paybandMax = d3.max(paybands, (d) => d[paybandMaxAttr]);

    const benchmarkMinAccessor =
      benchmarkType === BenchmarkType.Local
        ? R.prop("external_benchmark_25")
        : R.prop("external_benchmark_25_base");
    const benchmarkMaxAccessor =
      benchmarkType === BenchmarkType.Local
        ? R.prop("external_benchmark_75")
        : R.prop("external_benchmark_75_base");

    const benchmarkMin = d3.min(benchmarks, benchmarkMinAccessor);
    const benchmarkMax = d3.max(benchmarks, benchmarkMaxAccessor);

    const min = R.pipe(
      R.reject(R.isNil),
      R.reduce(R.min, Infinity),
      Number
    )([actualMin, paybandMin, benchmarkMin]);

    const max = R.pipe(
      R.reject(R.isNil),
      R.reduce(R.max, -Infinity),
      Number
    )([actualMax, paybandMax, benchmarkMax]);

    const subkeys = R.flatten(
      R.map((d: Meta) => {
        return this.expandedState[d.key]
          ? R.times(
              (i: number) => `${d.key}${SUBKEY_SEPARATOR}${i}`,
              SCATTER_PLOT_MULTIPLIER
            )
          : `${d.key}${SUBKEY_SEPARATOR}0`;
      }, groupedData)
    );

    return {
      x: d3
        .scaleLinear()
        .domain([Math.max(0, Math.max(0, min) * 0.95), 1.05 * max])
        .range([this.margin.left, this.width - this.margin.right]),
      y: d3
        .scaleBand()
        .domain(subkeys)
        .range([this.margin.top, this.height - this.margin.bottom]),
      legend: this.props.extraProps.colorScale,
    };
  }

  get expansionState(): ExpansionState {
    const states: boolean[] = R.values(this.expandedState);

    const none: boolean = R.all(R.equals(false), states);
    const all: boolean = R.all(R.equals(true), states);

    if (none) {
      return ExpansionState.Collapsed;
    } else if (all) {
      return ExpansionState.Expanded;
    }

    return ExpansionState.Indeterminate;
  }

  expandAll(): void {
    this.expandedState = R.mapObjIndexed(R.always(true), this.expandedState);
    this.update(this.props);

    this.emitter.emit(
      RangeAdheranceChartEvent.ExpansionStateChange,
      this.expansionState
    );
  }

  collapseAll(): void {
    this.expandedState = R.mapObjIndexed(R.always(false), this.expandedState);
    this.update(this.props);

    this.emitter.emit(
      RangeAdheranceChartEvent.ExpansionStateChange,
      this.expansionState
    );
  }

  getBaseMargin(): Margin {
    const base = this.props.extraProps.margin || {
      top: 50,
      right: 25,
      bottom: 50,
      left: 350,
    };

    return {
      top: base.top,
      right: this.props.extraProps.showLegend ? base.right + 275 : base.right,
      bottom: base.bottom,
      left:
        getIconBuffer(this.props) +
        (this.props.extraProps.renderCompact ? 10 : base.left),
    };
  }

  protected beforeUpdate(): void {
    const {
      data,
      width,
      extraProps: { renderCompact, yAttr, yKeyAttr },
    } = this.props;

    this.width = width;

    this.expandedState = R.pipe(
      R.groupBy(getYValue(yKeyAttr || yAttr)),
      R.keys,
      R.map((k: string) => ({
        [k]: R.pathOr(false, ["expandedState", k], this),
      })),
      R.mergeAll
    )(data);

    this.onIconClick = (_e: MouseEvent, d: IconData) => {
      this.expandedState[d.key] = !this.expandedState[d.key];
      this.update(this.props);
      this.emitter.emit(
        RangeAdheranceChartEvent.ExpansionStateChange,
        this.expansionState
      );
    };

    this.props.extraProps.computed = getComputedProps({
      props: this.props,
      ctx: this,
    });

    const { groupedData } = this.props.extraProps.computed;

    const keys = R.pluck("key", groupedData);

    // if any group is expanded, we add group length * BAR_HEIGHT to the height.
    // otherwise it's just BAR_HEIGHT for each group
    const barHeight = R.propOr(
      BAR_HEIGHT,
      "barHeight",
      this.props.extraProps
    ) as number;

    this.height =
      this.margin.top +
      this.margin.bottom +
      R.reduce(
        (acc: number, val: string) => {
          return (acc += this.expandedState[val]
            ? barHeight * SCATTER_PLOT_MULTIPLIER
            : barHeight);
        },
        0,
        keys
      );

    this.svg
      .attr("viewBox", `0,0,${width},${this.height}`)
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
    this.svg
      .selectAll("text.x.label")
      .attr("x", this.width / 2)
      .attr("y", this.height - 6);

    if (renderCompact) {
      this.svg.select("g.y.axis").selectAll(".tick text").remove();
      this.svg.select("g.xTop.axis").selectAll(".tick text").remove();
    }
  }
}

const createChart = (ref: HTMLElement, props: PaybandChartComponentProps) => {
  return new D3RangeAdheranceChart(ref, props);
};

export const RangeAdheranceChart: React.FC<RangeAdheranceChartProps> = ({
  data,
  onInitialize = R.always(undefined),
  ...props
}) => {
  React.useEffect(() => {
    // remove the tooltip on unmount
    return () => {
      d3.selectAll(".employee-tooltip").remove();
      d3.selectAll(".benchmark-tooltip").remove();
    };
  }, []);
  return (
    <D3Container
      data={data}
      onInitialize={onInitialize}
      D3ComponentFactory={createChart}
      {...props}
    />
  );
};
