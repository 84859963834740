import type { Grouped, Meta, Params } from "../types";

import * as R from "ramda";

import { createOverlayTooltip } from "../tooltip";

export const updateOverlay = R.curry(
  (
    { props, scales, ctx }: Params,
    g: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>
  ) => {
    const { x, y } = scales;

    const [xMin, xMax] = x.range();

    const { mouseover, mousemove, mouseleave } = createOverlayTooltip(
      g.node() as SVGGElement
    );

    return g
      .attr("fill", "none")
      .style("pointer-events", "all")
      .attr("x", xMin)
      .attr("y", (d: Meta) => y(R.pathOr("", ["grouped", 0, "subkey"], d)))
      .attr("width", xMax - xMin)
      .attr("height", y.bandwidth())
      .on("mouseover", mouseover)
      .on("mousemove", mousemove({ props, scales, ctx }))
      .on("mouseleave", mouseleave);
  }
);

export function drawOverlay(
  g: d3.Selection<d3.BaseType, unknown, d3.BaseType, Grouped>,
  params: Params
) {
  const { props } = params;
  const { groupedData } = props.extraProps.computed;

  const overlays = g.selectAll("rect").data(groupedData).join("rect");

  overlays.call(updateOverlay(params));
}
