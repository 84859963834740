import * as d3 from "d3";

export const Transitions = {
  Cubic: (): d3.Transition<d3.BaseType, unknown, d3.BaseType, unknown> =>
    d3.transition().duration(250).ease(d3.easeCubic),
  Standard: (): d3.Transition<d3.BaseType, unknown, d3.BaseType, unknown> =>
    d3.transition().duration(500),
  Bouncy: (): d3.Transition<d3.BaseType, unknown, d3.BaseType, unknown> =>
    d3.transition().duration(250).ease(d3.easeBackOut),
};
