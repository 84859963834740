import type { RegionRectangle } from "./data/regions";
import type { HydratedEmployee } from ".generated/models";

import React from "react";
import * as R from "ramda";

import { SubTitle } from "components/library";
import { createFormatter } from "lib/numberFormatter";
import { DECIMAL_PRECISION } from "util/format";
import { percentize } from "@milio/lib/util/percent/util";
import { PercentFormat } from "@milio/lib/util/percent/constant";

export interface RegionCardProps {
  employees: HydratedEmployee[];
  payAttr: string;
  region: RegionRectangle;
  currency: string;
}

export const RegionTooltip: React.FC<RegionCardProps> = ({
  employees,
  region,
  payAttr,
  currency,
}) => {
  const currencyFormatter = React.useMemo(() => {
    return createFormatter({
      style: "currency",
      currency,
      digits: DECIMAL_PRECISION,
    });
  }, [employees]);

  const thisRegion = R.filter((e: HydratedEmployee) => {
    const pay = e[payAttr];
    return (
      pay >= region.x1 && pay < region.x2 && pay >= region.y1 && pay < region.y2
    );
  }, employees);

  const currentCount = thisRegion.length;
  const allCount = employees.length;
  const pct = percentize(currentCount / allCount, {
    input: PercentFormat.Decimal,
    output: PercentFormat.Percentage,
    precision: 2,
  });
  const label = `${currentCount} / ${allCount} (${pct}%)`;

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
        <div className="min-w-0 flex-1">
          <a href="#" className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900">{region.label}</p>
            <p className="truncate text-sm text-gray-500">
              {currentCount > 0 ? label : "No employees"}
            </p>
            {currentCount > 0 && (
              <SubTitle>
                Avg. Comp:{" "}
                {currencyFormatter.format(
                  R.mean(R.map(R.prop(payAttr), thisRegion))
                )}
              </SubTitle>
            )}
          </a>
        </div>
      </div>
    </div>
  );
};
