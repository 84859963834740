export const BAR_HEIGHT = 60;

// Multiplier of BAR_HEIGHT used for the detail scatter plot
export const SCATTER_PLOT_MULTIPLIER = 8;

// Controls how aggressive the grouping is.
// Smaller # = fewer groups
export const GROUPING_MODIFIER = 10;

export const ICON_BUFFER = 30;
export const BUCKET_COUNT = 5;
export const BUCKET_COUNT_SM = 3;
export const SUBKEY_SEPARATOR = "##<>##";
export const TICKS = 4;

export const BENCHMARK_LABELS = {
  external_benchmark_25: "25th",
  external_benchmark_25_base: "25th",
  external_benchmark_50: "50th",
  external_benchmark_50_base: "50th",
  external_benchmark_75: "75th",
  external_benchmark_75_base: "75th",
};

export const BENCHMARK_PATHS = [
  ["benchmark", "pct_25"],
  ["benchmark", "pct_50"],
  ["benchmark", "pct_75"],
];

export const BASE_BENCHMARK_PATHS = [
  ["pct_25_base"],
  ["pct_50_base"],
  ["pct_75_base"],
];

export const BENCHMARK_FIELDS = [
  "external_benchmark_25",
  "external_benchmark_50",
  "external_benchmark_75",
];

export const BASE_BENCHMARK_FIELDS = [
  "external_benchmark_25_base",
  "external_benchmark_50_base",
  "external_benchmark_75_base",
];

export const HOURLY_EQUIVALENTS = {
  pay: "pay_hourly",
  pay_base: "pay_base_hourly",
  pay_band_min: "pay_band_min_hourly",
  pay_band_max: "pay_band_max_hourly",
  pay_band_min_base: "pay_band_min_base_hourly",
  pay_band_max_base: "pay_band_max_base_hourly",
};
