import * as d3 from "d3";
import * as R from "ramda";

export const wrap =
  (width: number, padding: number) =>
  (_d: unknown, i: number, nodes: SVGTextElement[]): void => {
    const node = d3.select(nodes[i]);
    let textLength = node.node().getComputedTextLength();
    let text = node.text();
    while (textLength > width - 2 * padding && text.length > 0) {
      text = text.slice(0, -1);
      node.text(text + "...");
      textLength = node.node().getComputedTextLength();
    }
  };

export const getLongestTextElementInSelection = (
  selection: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>
): number => {
  const data = R.map(
    (n: SVGTextElement) => n.getComputedTextLength(),
    selection.nodes() as SVGTextElement[]
  );
  return R.reduce(R.max, -Infinity, data) as number;
};
