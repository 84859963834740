import type { Meta, Data, PaybandChartComponentProps, Grouped } from "../types";

import * as R from "ramda";

import { GROUPING_MODIFIER, SUBKEY_SEPARATOR } from "../constant";
import { getBenchmarkFields, getPayAttributes } from "../props";
import { getYValue } from "../util";
import { toAnnualizedValues } from "@milio/lib/util/math/util";
import { HydratedEmployee } from ".generated/models";

const createArray = (o: any) => {
  return R.objOf(o.scenario + o.label, [] as Data[]);
};

const groupByY = (props: PaybandChartComponentProps) => {
  const {
    extraProps: { yAttr, yKeyAttr },
  } = props;
  const getYKey = getYValue(yKeyAttr || yAttr);

  return R.groupBy(getYKey);
};

const addEmptyRows = (props: PaybandChartComponentProps) => {
  const {
    extraProps: { emptyGroups },
  } = props;

  const emptyRows = R.pipe(R.map(createArray), R.mergeAll)(emptyGroups || []);

  return (x) => {
    return R.mergeRight(emptyRows, x);
  };
};

interface GroupFnProps {
  intervalFn: (d: Data[]) => [number, number][];
  attributeFn: (d: Data[]) => Grouped;
  datum: Meta;
  payAttr: string;
}

const groupByPayBucket = R.curry(
  ({ intervalFn, attributeFn, payAttr, datum }: GroupFnProps) => {
    const intervals = intervalFn(datum.data);
    const groups = R.map(([lower, upper]: [number, number]) => {
      const pred = R.both(R.lte(lower), R.gt(upper));
      // get all the data points in this interval
      const group = R.filter(R.where({ [payAttr]: pred }), datum.data);

      if (group.length <= 1) return group;
      const groupPay = R.map(R.prop(payAttr), group);
      // change each data point's pay to the group midpoint
      // Can revisit to draw more data points if space allows
      return R.map(
        R.set(R.lensProp(payAttr as keyof Data), R.mean(groupPay)),
        group
      );
    }, intervals);
    return R.pipe(R.reject(R.isEmpty), R.map(attributeFn), R.flatten)(groups);
  }
);

const addIntervalIfEnoughSpace = R.curry(
  (
    smallestPossibleIntervalWidth: number,
    acc: number[],
    thisBreakpoint: number
  ) => {
    if (smallestPossibleIntervalWidth === 0)
      return R.append(thisBreakpoint, acc);

    let prevBreakpoint = R.last(acc);
    const additionalBreakpoints = [];

    while (
      prevBreakpoint &&
      thisBreakpoint - prevBreakpoint > smallestPossibleIntervalWidth
    ) {
      const newBreakpoint = prevBreakpoint + smallestPossibleIntervalWidth;
      additionalBreakpoints.push(newBreakpoint);
      prevBreakpoint = newBreakpoint;
    }

    return R.concat(acc, [...additionalBreakpoints, thisBreakpoint]);
  }
);

export function groupData(props: PaybandChartComponentProps): Meta[] {
  const {
    data,
    extraProps: { colorAttr, getGroupColor, yAttr, yKeyAttr, useHourly },
  } = props;
  const { payAttr, paybandMinAttr, paybandMaxAttr } = getPayAttributes(props);
  const benchmarkFields = getBenchmarkFields(props);

  const getYKey = getYValue(yKeyAttr || yAttr);
  const intervalProps =
    payAttr === ("role_tenure" as keyof HydratedEmployee)
      ? []
      : [paybandMinAttr, paybandMaxAttr, ...benchmarkFields];

  // Pull out all the numbers that could affect the bounds of the graph.
  const relevantPayNumbers = R.pipe(
    R.map(R.props([payAttr, ...intervalProps]) as (d: Data) => number[]),
    R.flatten,
    R.uniq,
    R.reject(R.isNil)
  )(data) as number[];

  const [lowerBound, upperBound] = [
    R.reduce(R.min<number>, Infinity, relevantPayNumbers),
    R.reduce(R.max<number>, -Infinity, relevantPayNumbers),
  ];

  const addInterstitialIntervals = R.reduce(
    addIntervalIfEnoughSpace(
      (upperBound - lowerBound) /
        (useHourly ? toAnnualizedValues(GROUPING_MODIFIER) : GROUPING_MODIFIER)
    ),
    []
  );

  // Get a list of points we should use to subdivide the X-Axis for further bucketing
  const toBreakpointIntervals = R.pipe(
    R.head,
    R.props(intervalProps),
    R.reject(R.isNil),
    R.sortBy(R.identity<number>),
    R.prepend(lowerBound),
    // We add 1 here so that we don't miss the data point that is at the upper bound
    // when filtering based on interval
    R.append(upperBound + 1),
    R.uniq,
    addInterstitialIntervals,
    R.aperture(2)
  ) as (d: Data[]) => [number, number][];

  // begin constructing the Meta data point for each row, which has all the relevant datapoints in `data`.
  const toMeta = (val: Data[], key: string) => {
    return {
      key,
      data: R.pipe(
        R.sortWith([R.ascend(getYKey), R.ascend(R.prop(payAttr))]),
        R.addIndex(R.map)((d: Data, i: number) => {
          return R.assoc("subkey", `${key}${SUBKEY_SEPARATOR}${i + 1}`, d);
        })
      )(val),
    };
  };

  const setGroupAttributes = (group: Data[]) => {
    return {
      [payAttr]: R.path([0, payAttr], group),
      size: R.length(group),
      subkey: `${getYKey(R.head(group))}${SUBKEY_SEPARATOR}0`,
      [colorAttr]: getGroupColor(group, props),
    } as unknown as Grouped;
  };

  const addGrouped = (d: Meta) =>
    R.assoc(
      "grouped",
      groupByPayBucket({
        intervalFn: toBreakpointIntervals,
        attributeFn: setGroupAttributes,
        datum: d,
        payAttr,
      }),
      d
    );

  const powerhouseFn = R.pipe(
    groupByY(props),
    addEmptyRows(props),
    R.mapObjIndexed(toMeta),
    R.values as () => Meta[],
    R.sort(R.ascend(R.prop("key"))),
    R.map(addGrouped)
  ) as unknown as (d: Data[]) => Meta[];

  const result = powerhouseFn(data);

  return result;
}
