import * as R from "ramda";
import * as d3 from "d3";
import React from "react";

import * as Style from "style";
import {
  D3ComponentProps,
  D3Container,
  D3ContainerProps,
} from "components/D3Container";
import { createFormatter } from "lib/numberFormatter";
import { LineGraphCurve } from "./util/curves";
import { format } from "./util";
import { BaseD3Component, EventHandlers, GroupSelection } from "lib/d3";
import { EmployeeTimelineData } from "./type";

const HEIGHT = 300;
const COMPACT_HEIGHT = 8;
const FIXED_Y = 4;
interface D3Props {
  currency: string;
  colorScale: d3.ScaleOrdinal<string, string>;
  renderCompact: boolean;
}
const sortAscending = R.sort(R.ascend<EmployeeTimelineData>(R.prop("date")));

enum TooltipPosition {
  Left,
  Right,
}

const createTooltip = (
  container: SVGSVGElement
): EventHandlers<
  EmployeeTimelineData[],
  EmployeeTimelineComponentProps,
  Scales
> => {
  const Tooltip = d3
    .select("body")
    .append("div")
    .style("opacity", 0)
    .attr(
      "class",
      "timeline-tooltip whitespace-normal absolute bg-white border border-gray-300 rounded text-gray-600 opacity-75 p-3 mb-0 z-10"
    );

  const mouseover = function () {
    Tooltip.style("opacity", 1);
  };
  const mouseleave = function (e: MouseEvent) {
    if (e.target === e.currentTarget) {
      Tooltip.style("opacity", 0);
      d3.select(container).selectAll("circle").attr("stroke", null);
    }
  };

  const mousemove = function (
    props: EmployeeTimelineComponentProps,
    scales: Scales
  ) {
    const {
      data,
      extraProps: { renderCompact },
    } = props;
    const { x, y } = scales;

    return function (e: MouseEvent) {
      const sorted = sortAscending(data);
      const [eventX] = d3.pointer(e);
      const xDate = x.invert(eventX);
      const i = d3.bisectCenter(R.pluck("date", sorted), xDate);

      if (!sorted[i]) return;

      const yPos = renderCompact ? FIXED_Y : y(sorted[i].value);
      const xPos = x(sorted[i].date);
      const position = renderCompact
        ? TooltipPosition.Left
        : TooltipPosition.Right;

      let html = '<ul class="w-96">';

      for (const tt of sorted[i].tooltips) {
        html += `
        <li>
          <div class="flex space-x-3">
          ${tt}
          </div>
        </li>
        `;
      }

      html += "</ul>";

      Tooltip.html(html);

      const { x: containerX, y: containerY } =
        container.getBoundingClientRect();
      switch (position) {
        case TooltipPosition.Left:
          const { width } = Tooltip.node().getBoundingClientRect();
          Tooltip.style("left", `${containerX + xPos - width - 5}px`).style(
            "top",
            `${containerY + yPos + 5}px`
          );
          break;
        case TooltipPosition.Right:
        default:
          Tooltip.style("left", `${containerX + xPos + 5}px`).style(
            "top",
            `${containerY + yPos + 5}px`
          );
          break;
      }

      d3.select(container)
        .selectAll("circle")
        .filter((d: EmployeeTimelineData) => x(d.date) === x(sorted[i].date))
        .attr("stroke", "black");
      d3.select(container)
        .selectAll("circle")
        .filter((d: EmployeeTimelineData) => x(d.date) !== x(sorted[i].date))
        .attr("stroke", null);
    };
  };

  return { mouseover, mousemove, mouseleave };
};

const drawDots = (
  g: GroupSelection<EmployeeTimelineData[]>,
  { props, scales }: { props: EmployeeTimelineComponentProps; scales: Scales }
) => {
  const {
    data,
    extraProps: { renderCompact, colorScale },
  } = props;
  const { x, y } = scales;
  const dots = g.selectAll("circle").data(data);
  dots.join(
    (enter) =>
      enter
        .append("circle")
        .attr("cx", (d: EmployeeTimelineData) => x(d.date))
        .attr("cy", (d: EmployeeTimelineData) =>
          renderCompact ? FIXED_Y : y(d.value)
        )
        .attr("r", 4)
        .attr("opacity", 1)
        .attr("fill", (d) => colorScale(d.type)),
    (update) =>
      update
        .attr("cx", (d: EmployeeTimelineData) => x(d.date))
        .attr("cy", (d: EmployeeTimelineData) =>
          renderCompact ? FIXED_Y : y(d.value)
        ),
    (exit) => exit.remove()
  );
};

const drawLine = (
  g: GroupSelection<EmployeeTimelineData[]>,
  { props, scales }
) => {
  const {
    data,
    extraProps: { renderCompact },
  } = props;
  const { x, y } = scales;

  if (renderCompact) return;

  const sorted = sortAscending(data);
  const withToday = [
    ...sorted,
    { date: new Date(), value: R.propOr(0, "value", R.last(sorted)) },
  ];
  g.selectAll("path").remove();
  g.append("path")
    .datum(withToday)
    .attr("fill", "none")
    .attr("stroke", Style.Color.Layout.Line)
    .attr("stroke-linecap", "round")
    .attr("stroke-width", 8)
    .attr(
      "d",
      d3
        .line<EmployeeTimelineData>()
        .x((d) => {
          return x(d.date);
        })
        .y((d) => (renderCompact ? FIXED_Y : y(d.value)))
        .curve(LineGraphCurve) as d3.ValueFn<d3.BaseType, unknown, string>
    );
};

interface Scales {
  x: d3.ScaleTime<number, number>;
  y: d3.ScaleLinear<number, number>;
}

export type EmployeeTimelineComponentProps = D3ComponentProps<
  EmployeeTimelineData[],
  D3Props
>;
export type EmployeeTimelineProps = Omit<
  D3ContainerProps<EmployeeTimelineData[], D3Props>,
  "width" | "height"
>;

class EmployeeTimelineChart extends BaseD3Component<
  EmployeeTimelineData[],
  EmployeeTimelineComponentProps,
  Scales
> {
  width: number;
  height: number;
  eventHandlers: EventHandlers<
    EmployeeTimelineData[],
    EmployeeTimelineComponentProps,
    Scales
  >;

  constructor(container: HTMLElement, props: EmployeeTimelineComponentProps) {
    super(container, props);
    const { width } = props;
    this.width = width;

    Style.D3Text.Title(this.svg.select("g.x.axis"));
    Style.D3Text.Title(this.svg.append("g.y.axis"));

    this.eventHandlers = createTooltip(this.svg.node());
    this.svg.on("mouseleave", this.eventHandlers.mouseleave);
    this.svg.on("mouseover", this.eventHandlers.mouseover);
  }

  getBaseMargin() {
    const { renderCompact } = this.props.extraProps;
    return {
      top: renderCompact ? 0 : 30,
      right: renderCompact ? FIXED_Y : 50,
      bottom: renderCompact ? 0 : 30,
      left: renderCompact ? FIXED_Y : 80,
    };
  }

  createScales(props: EmployeeTimelineComponentProps): Scales {
    const { data } = props;
    const [salaryMin, salaryMax] = d3.extent(data, R.prop("value")) as [
      number,
      number
    ];
    const x = d3
      .scaleTime()
      .domain([d3.min(data, R.prop("date")), new Date()] as Date[])
      .range([this.margin.left, this.width - this.margin.right])
      .nice();
    const y = d3
      .scaleLinear()
      .domain([Math.max(0, 0.9 * salaryMin), 1.1 * salaryMax])
      .range([this.height - this.margin.bottom, this.margin.top])
      .nice();
    return { x, y };
  }

  getGraphAxes(): {
    [key: string]: (
      container: GroupSelection<EmployeeTimelineData[]>,
      {
        props,
        scales,
        ctx,
      }: {
        props: EmployeeTimelineComponentProps;
        scales: Scales;
        ctx: BaseD3Component<
          EmployeeTimelineData[],
          EmployeeTimelineComponentProps,
          Scales
        >;
      }
    ) => void;
  } {
    return {
      x: (
        g,
        {
          props: {
            extraProps: { renderCompact },
          },
          scales: { x },
        }
      ) =>
        !renderCompact &&
        g
          .attr("transform", `translate(0,${this.height - this.margin.bottom})`)
          .call(
            d3
              .axisBottom(x)
              .ticks(5)
              .tickFormat(d3.timeFormat("%b '%y"))
              .tickSize(0)
          )
          .call((g) => g.selectAll(".domain").remove()),
      y: (
        g,
        {
          props: {
            extraProps: { currency, renderCompact },
          },
          scales: { y },
        }
      ) => {
        !renderCompact &&
          Style.D3.Layout.Line(
            g
              .attr("transform", `translate(${this.margin.left},0)`)
              .call(
                d3
                  .axisRight(y)
                  .ticks(5)
                  .tickFormat(
                    format(createFormatter({ style: "currency", currency }))
                  )
                  .tickSize(this.width)
              )
              .call((g) =>
                Style.D3Text.Title(g.selectAll(".tick text")).attr(
                  "x",
                  `${-this.margin.left}`
                )
              )
              .call((g) => g.selectAll(".domain").remove())
          );
      },
    };
  }

  getGraphElements(): {
    [key: string]: (
      container: GroupSelection<EmployeeTimelineData[]>,
      {
        props,
        scales,
        ctx,
      }: {
        props: EmployeeTimelineComponentProps;
        scales: Scales;
        ctx: BaseD3Component<
          EmployeeTimelineData[],
          EmployeeTimelineComponentProps,
          Scales
        >;
      }
    ) => void;
  } {
    return {
      line: drawLine,
      dots: drawDots,
    };
  }

  protected beforeUpdate(): void {
    const {
      width,
      extraProps: { renderCompact },
    } = this.props;

    this.width = width;

    this.height =
      this.margin.top +
      this.margin.bottom +
      (renderCompact ? COMPACT_HEIGHT : HEIGHT);

    this.svg.attr("viewBox", `0,0,${this.width},${this.height}`);

    this.svg.on(
      "mousemove",
      this.eventHandlers.mousemove(this.props, this.createScales(this.props))
    );
  }
}

const createChart = (
  ref: HTMLElement,
  props: EmployeeTimelineComponentProps
) => {
  return new EmployeeTimelineChart(ref, props);
};

export const EmployeeTimeline: React.FC<EmployeeTimelineProps> = (props) => {
  React.useEffect(() => {
    return () => {
      d3.selectAll(".timeline-tooltip").remove();
    };
  }, []);
  return <D3Container D3ComponentFactory={createChart} {...props} />;
};
