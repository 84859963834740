import type { RegionRectangle } from "./data/regions";

import * as R from "ramda";
import * as d3 from "d3";
import { renderToStaticMarkup } from "react-dom/server";

import { BENCHMARK_FIELDS, SUBKEY_SEPARATOR } from "./constant";
import { EmployeeCardSmall } from "components/Employee/EmployeeCardSmall";
import {
  Data,
  Grouped,
  Meta,
  Params,
  PaybandChartComponentProps,
} from "./types";
import { RegionTooltip } from "./RegionTooltip";
import { BenchmarkTooltip, EmployeeBenchmark } from "./BenchmarkTooltip";
import { PaybandTooltip, PaybandTooltipData } from "./PaybandTooltip";
import { GroupTooltip } from "./GroupTooltip";
import { getBenchmarkAccessor, getPayAttributes } from "./props";
import { toHourlyBenchmarks } from "./util";

const distance = (p1: [number, number], p2: [number, number]): number => {
  const sq = R.curry(R.flip(Math.pow))(2);
  return Math.sqrt(sq(p2[0] - p1[0]) + sq(p2[1] - p1[1]));
};

const BUFFER = 5;
const getRegionAtPoint = ({
  point,
  regions,
}: {
  point: [number, number];
  regions: RegionRectangle[];
}): RegionRectangle => {
  return R.find(
    R.where({
      x1: R.lt(R.__, point[0]),
      x2: R.gte(R.__, point[0]),
      y1: R.lt(R.__, point[1]),
      y2: R.gte(R.__, point[1]),
    }),
    regions
  );
};

// IF you are within this many pixels (circular) from an employee dot, show the tooltip, otherwise we will show a region hover
const NEAR_EMPLOYEE_DOT = 25;

const NEAR_GRAPH_ELEMENT = 10;
const NEAR_BENCHMARK_TICK = 5;

export const createEmployeeTooltip = (container: SVGElement) => {
  d3.select(container).style("pointer-events", "none");
  const Tooltip = d3.select("div.employee-tooltip").node()
    ? d3.select("div.employee-tooltip")
    : d3
        .select("body")
        .append("div")
        .attr(
          "class",
          `employee-tooltip absolute block bg-white z-10 pointer-events-none`
        );

  const mouseover = function () {
    Tooltip.style("visibility", "visible");
  };
  const mouseleave = function (e: MouseEvent) {
    if (e.target === e.currentTarget) {
      Tooltip.style("visibility", "hidden");
      const parent = d3.select(this).node().parentNode;
      d3.select(parent).selectAll("circle").attr("stroke", null);
      d3.select(parent).selectAll("rect.region").style("opacity", 0);
    }
  };

  const mousemove = (props: PaybandChartComponentProps, scales: any) => {
    return function (e: MouseEvent, d: Meta) {
      const [eventX, eventY] = d3.pointer(e);
      const { currency } = props.extraProps;
      const { payAttr, getYKey, regions, paybandMinAttr, paybandMaxAttr } =
        props.extraProps.computed;
      const { useHourly } = props.extraProps;
      Tooltip.style("visibility", "visible");

      const xValue = scales.x.invert(eventX);
      const yValue = scales.y.invert(eventY);

      const i = d3.bisectCenter(R.range(0, d.data.length), xValue);

      const employee = d.data[i];
      let pay = R.prop<number>(payAttr, employee);

      const min = R.prop<number>(paybandMinAttr, employee);
      const max = R.prop<number>(paybandMaxAttr, employee);

      // Edge case: If all the employees are above/below band, we nudge the pay across the line so we show the correct region highlight.
      if (i === 0 && pay >= min && xValue < -0.5) {
        pay = min - 1;
      } else if (i === d.data.length - 1 && xValue >= i + 0.5) {
        pay = max + 1;
      }

      if (!employee) return;

      const region = getRegionAtPoint({
        point: [pay, yValue],
        regions: R.propOr([], getYKey(employee), regions),
      });

      const dotCenter: [number, number] = [
        scales.x(i),
        scales.y(employee[payAttr]),
      ];

      const { x: containerX, y: containerY } =
        container.getBoundingClientRect();

      const parent = d3.select(this).node().parentNode;
      d3.select(parent).selectAll("circle").attr("stroke", null);
      d3.select(parent).selectAll("rect.region").style("opacity", 0);

      const distanceToDot = distance(dotCenter, [eventX, eventY]);

      if (distanceToDot < NEAR_EMPLOYEE_DOT) {
        const html = renderToStaticMarkup(
          <EmployeeCardSmall
            employee={employee}
            hourly={useHourly}
            settings={{ base_currency: currency }}
          />
        );
        const tooltipPosX = scales.x(i) + containerX + BUFFER;
        const tooltipPosY = scales.y(employee[payAttr]) + containerY + BUFFER;
        Tooltip.html(html);
        Tooltip.style("top", `${tooltipPosY}px`).style(
          "left",
          `${tooltipPosX}px`
        );

        d3.select(parent)
          .selectAll("circle")
          .filter((_d: Data, idx: number) => idx === i)
          .attr("stroke", "black");
      } else if (!R.isNil(region)) {
        const currency = R.pathOr("USD", ["extraProps", "currency"], props);
        const html = renderToStaticMarkup(
          <RegionTooltip
            employees={d.data}
            region={region}
            payAttr={payAttr}
            currency={currency}
          />
        );
        Tooltip.html(html);
        Tooltip.style("top", `${eventY + containerY + BUFFER}px`).style(
          "left",
          `${eventX + containerX + BUFFER}px`
        );
        d3.select(parent)
          .selectAll("rect.region")
          .filter((r: RegionRectangle) => {
            return r.rectangleType === region.rectangleType;
          })
          .style("opacity", 0.3);
      } else {
        Tooltip.style("visibility", "hidden");
      }
    };
  };
  return { mouseover, mousemove, mouseleave };
};

function getClosestBenchmark(
  xValue: number,
  data: Data[],
  useHourly: boolean
): [EmployeeBenchmark, number] {
  const benchmarkMetadata = [
    "external_benchmark_survey_date",
    "external_benchmark_original_survey_date",
    "external_benchmark_aging_factor",
    "currency",
  ];

  const toBenchmark = getBenchmarkAccessor(R.always(undefined));

  if (!data) return;

  const benchmarks = R.pipe(
    useHourly ? R.map(toHourlyBenchmarks) : R.identity,
    R.map(toBenchmark),
    R.head,
    R.pick(BENCHMARK_FIELDS),
    R.toPairs
  )(data);

  const i = d3.bisectCenter(
    benchmarks.map((d) => d[1] as number),
    xValue
  );

  const closestBenchmark = {
    type: benchmarks[i][0],
    value: benchmarks[i][1],
  };

  if (R.isNil(closestBenchmark.value)) return [null, -1];

  return [
    R.mergeRight(
      R.pick(benchmarkMetadata, R.head(data)),
      closestBenchmark
    ) as unknown as EmployeeBenchmark,
    i,
  ];
}

function getClosestGroup(
  xValue: number,
  payAttr: string,
  data: Grouped[]
): [Grouped, number] {
  const i = d3.bisectCenter(
    data.map((d) => d[payAttr]),
    xValue
  );

  if (i < 0 || i >= data.length) return [null, -1];

  return [data[i], i];
}

function getClosestPayband(
  xValue: number,
  key: string,
  props: PaybandChartComponentProps
): [PaybandTooltipData, number] {
  const { paybandMinAttr, paybandMaxAttr } = getPayAttributes(props);
  const {
    extraProps: {
      computed: { paybands, getYValue, getYKey },
    },
  } = props;

  const thisPayband = R.find((p) => getYKey(p) === key, paybands);
  const paybandIndex = R.findIndex((p) => getYKey(p) === key, paybands);
  const min = R.prop(paybandMinAttr, thisPayband);
  const max = R.prop(paybandMaxAttr, thisPayband);
  const prevMax = R.pathOr(null, [paybandIndex - 1, paybandMaxAttr], paybands);
  const nextMin = R.pathOr(null, [paybandIndex + 1, paybandMinAttr], paybands);
  const isInPayband = min <= xValue && max >= xValue;

  if (!isInPayband) return [null, -1];
  return [
    {
      currency: thisPayband.currency,
      min,
      max,
      prevMax,
      nextMin,
      title: getYValue(thisPayband),
    },
    paybandIndex,
  ];
}

export const createOverlayTooltip = (container: SVGGElement) => {
  const Tooltip = d3.select("div.overlay-tooltip").node()
    ? d3.select("div.overlay-tooltip")
    : d3
        .select("body")
        .append("div")
        .attr(
          "class",
          `overlay-tooltip absolute block bg-white z-10 pointer-events-none rounded-md`
        );

  const mouseover = function () {
    Tooltip.style("visibility", "visible");
  };

  const mouseleave = function (e: MouseEvent) {
    if (e.target === e.currentTarget) {
      Tooltip.style("visibility", "hidden");
      const parent = d3.select(this).node().parentNode.parentNode;
      d3.select(parent).selectAll("rect.tick").style("stroke", null);
    }
  };

  const mousemove = (params: Params) => {
    const {
      ctx,
      scales: { x, y },
      props: {
        extraProps: { useHourly, currency },
      },
    } = params;
    const { payAttr } = getPayAttributes(params.props);
    return function (e: MouseEvent, d: Meta) {
      const [eventX] = d3.pointer(e);
      const parent = d3.select(this).node().parentNode.parentNode;

      d3.select(parent).selectAll("rect.tick").style("stroke", null);
      d3.select(parent).selectAll(".candlesticks rect").style("stroke", null);
      d3.select(parent).selectAll(".group circle").style("stroke", null);

      const xValue = x.invert(eventX);

      const [closestBenchmark, benchmarkIndex] = getClosestBenchmark(
        xValue,
        d.data,
        useHourly
      );

      const benchmarkDistance = distance(
        [eventX, 0],
        [x(R.propOr(Infinity, "value", closestBenchmark)), 0]
      );

      const [closestGroup, groupIndex] = getClosestGroup(
        xValue,
        payAttr,
        d.grouped
      );

      const groupDistance = distance(
        [eventX, 0],
        [x(R.propOr(Infinity, payAttr, closestGroup)), 0]
      );

      const [closestPayband, paybandIndex] = getClosestPayband(
        xValue,
        d.key,
        params.props
      );

      if (!closestBenchmark && !closestGroup && !closestPayband) {
        Tooltip.style("visibility", "hidden");
        return;
      }

      Tooltip.style("visibility", "visible");

      let xPos: number;
      let html = "";

      if (closestGroup && groupDistance < NEAR_GRAPH_ELEMENT) {
        xPos = x(R.prop(payAttr, closestGroup));
        html = renderToStaticMarkup(
          <GroupTooltip
            currency={currency}
            group={closestGroup}
            payAttr={payAttr}
          />
        );
        d3.select(parent)
          .selectAll(".group")
          .filter(function () {
            const key = (this as SVGGElement).dataset.group;
            return d.key === key;
          })
          .selectAll("circle")
          .filter((_d: unknown, idx: number) => idx === groupIndex)
          .style("stroke", "black");
      } else if (closestBenchmark && benchmarkDistance < NEAR_BENCHMARK_TICK) {
        xPos = x(closestBenchmark.value);
        html = renderToStaticMarkup(
          <BenchmarkTooltip benchmark={closestBenchmark} />
        );

        d3.select(parent)
          .selectAll(".benchmark-group")
          .filter(function () {
            const key = (this as SVGGElement).dataset.key;
            return d.key === key;
          })
          .selectAll(".tick")
          .filter((_d: unknown, idx: number) => idx === benchmarkIndex)
          .style("stroke", "black");
      } else if (closestPayband) {
        xPos = x((closestPayband.min + closestPayband.max) / 2);
        html = renderToStaticMarkup(
          <PaybandTooltip payband={closestPayband} />
        );
        d3.select(parent)
          .selectAll(".candlesticks rect")
          .filter((_d: unknown, idx: number) => idx === paybandIndex)
          .style("stroke", "black");
      }
      const yPos = y(`${d.key}${SUBKEY_SEPARATOR}0`) + y.bandwidth() / 4;

      const { x: containerX, y: containerY } =
        container.getBoundingClientRect();

      const tooltipPosX = xPos + containerX - ctx.margin.left + BUFFER;
      const tooltipPosY =
        yPos + containerY - ctx.margin.top + y.bandwidth() / 4;
      Tooltip.html(html);
      Tooltip.style("top", `${tooltipPosY}px`).style(
        "left",
        `${tooltipPosX}px`
      );
    };
  };
  return { mouseover, mousemove, mouseleave };
};
