import { GroupSelection } from "lib/d3";
import type { IconData, Params } from "../types";

import * as Style from "style";
import * as R from "ramda";
import { SUBKEY_SEPARATOR } from "../constant";

export const drawIcons = (
  g: GroupSelection<unknown>,
  { props, scales, ctx }: Params
) => {
  const { expandedState: state } = props.extraProps.computed;
  const iconData: IconData[] = R.values(
    R.mapObjIndexed(
      (val: boolean, key: string) => ({
        key,
        subkey: `${key}${SUBKEY_SEPARATOR}0`,
        expanded: val,
      }),
      state
    )
  );

  const { y } = scales;

  if (props.extraProps.hideToggleControls) {
    return;
  }

  g.selectAll("svg")
    .data(iconData, (d: IconData) => d.subkey)
    .join(
      (enter: d3.Selection<d3.BaseType, IconData, SVGGElement, unknown>) => {
        const styled = Style.D3Text.Title(
          enter
            .append("svg")
            .attr("class", "icon h-6 w-6 cursor-pointer")
            .attr("fill", "transparent")
            .attr("stroke", "currentColor")
            .on("click", ctx.onIconClick)
            .attr("y", (d) => y(d.subkey) + y.bandwidth() / 2 - 12)
            .append("path")
            .attr("stroke-linecap", "round")
            .attr("stroke-linejoin", "round")
            .attr("stroke-width", 2)
            .attr("d", (d) =>
              d.expanded
                ? "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                : "M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            )
        );
        return styled as d3.Selection<
          d3.BaseType,
          IconData,
          SVGGElement,
          undefined
        >;
      },
      (update) => {
        update
          .select("path")
          .attr("d", (d) =>
            d.expanded
              ? "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              : "M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          );

        update.attr("y", (d) => y(d.subkey) + y.bandwidth() / 2 - 12);
        return update;
      },

      (exit) => exit.remove()
    );
};
