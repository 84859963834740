import type { Data, Params } from "../types";

import * as R from "ramda";

import { GroupSelection } from "lib/d3";
import { SUBKEY_SEPARATOR } from "../constant";
import * as Style from "style";
import { Transitions } from "components/graphs/util/transitions";
import { D3LabelledBand } from "data/d3";

export const drawCandlesticks = (
  g: GroupSelection<Data[]>,
  { props, scales }: Params
) => {
  const { x, y } = scales;
  const { yHighlight, highlightColor } = props.extraProps;
  const { getYKey, paybandMinAttr, paybandMaxAttr, paybands } =
    props.extraProps.computed;

  const noPaybands = R.pipe(R.pluck(paybandMinAttr), R.all(R.isNil))(paybands);

  const yScale = y as D3LabelledBand;

  g.selectAll("g.candlestick")
    .data(noPaybands ? [] : paybands)
    .join(
      (enter) => {
        const newOnes = enter.filter((d) => d[paybandMinAttr] !== null);
        const group = newOnes
          .append("g")
          .attr("class", "candlestick")
          .attr("transform", (d: Data) => {
            return `translate(0, ${
              yScale(getYKey(d) + SUBKEY_SEPARATOR + "0") +
              yScale.bandwidth() / 2
            })`;
          })
          .attr("opacity", 1);
        group
          .filter((d: Data) => yHighlight.indexOf(getYKey(d)) > -1)
          .attr("color", highlightColor);
        group
          .filter((d: Data) => yHighlight.indexOf(getYKey(d)) === -1)
          .attr("color", null);
        group
          .append("rect")
          .attr("fill", Style.Color.Layout.Line)
          .attr("rx", 8)
          .attr("x", (d) => x(d[paybandMinAttr]))
          .attr("y", -8)
          .attr("width", (d) => x(d[paybandMaxAttr]) - x(d[paybandMinAttr]))
          .attr("height", 16);
        return group;
      },
      (update) => {
        update.filter((d: Data) => d[paybandMinAttr] === null).remove();
        update.attr("transform", (d: Data) => {
          return `translate(0, ${
            yScale(getYKey(d) + SUBKEY_SEPARATOR + "0") + yScale.bandwidth() / 2
          })`;
        });
        update
          .filter((d: Data) => yHighlight.indexOf(getYKey(d)) > -1)
          .attr("color", highlightColor);
        update
          .filter((d: Data) => yHighlight.indexOf(getYKey(d)) === -1)
          .attr("color", null);
        update
          .select("rect")
          .attr("x", (d) => x(d[paybandMinAttr]))
          .attr("width", (d) => x(d[paybandMaxAttr]) - x(d[paybandMinAttr]));
        return update;
      },
      (exit) => exit.transition(Transitions.Cubic()).attr("opacity", 0).remove()
    );
};
