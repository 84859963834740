import * as R from "ramda";
import * as d3 from "d3";
import React from "react";

import * as Style from "style";
import {
  D3Container,
  D3ComponentProps,
  D3ContainerProps,
} from "components/D3Container";
import { DateTime } from "luxon";
import { DEFAULT_DATE_FORMAT } from "@milio/lib/util/date";
import { LineGraphCurve } from "./util/curves";
import { BaseD3Component, GroupSelection, EventHandlers } from "lib/d3/types";
import { drawLegend } from "./util/legend";

const HEIGHT = 400;

const createTooltip = (
  container: SVGSVGElement
): EventHandlers<unknown, LineChartComponentProps, Scales> => {
  const Tooltip = d3
    .select(container.parentNode as d3.BaseType)
    .append("div")
    .style("opacity", 0)
    .attr(
      "class",
      "timeline-tooltip absolute bg-white border border-gray-300 rounded text-gray-600 opacity-75 p-3 mb-0 z-10 pointer-events-none"
    );

  const mouseover = function () {
    Tooltip.style("opacity", 1);
    d3.select(container)
      .selectAll(".focus")
      .attr("class", "focus show pointer-events-none");
  };

  const mouseleave = function () {
    Tooltip.style("opacity", 0);
    d3.select(container)
      .selectAll(".focus")
      .attr("class", "focus hidden pointer-events-none");
    d3.select(container)
      .selectAll("path")
      .attr("stroke-width", 2)
      .attr("opacity", 1);
  };

  const mousemove = function (props: LineChartComponentProps, scales: Scales) {
    const { x, y } = scales;
    const { data } = props;
    const { valueFormatter, xAttr, yAttr, yLabel } = props.extraProps;
    return function (e: MouseEvent) {
      const [eventX, eventY] = d3.pointer(e);

      const [xMin, xMax] = x.range();
      const [yMin, yMax] = y.range();

      if (xMin > eventX || xMax < eventX || yMax > eventY || yMin < eventY) {
        mouseleave();
        return;
      } else {
        mouseover();
      }

      const xPos = x.invert(eventX);
      const yPos = y.invert(eventY);

      const candidatePoints: LineDatum[] = R.pipe(
        R.map((d: LineData) => {
          const i =
            d3.bisector((d: LineDatum) => d.date).left(d.value, xPos) - 1;
          return {
            i,
            d: R.prop(i, d.value),
          };
        }),
        R.reject(R.whereEq({ i: -1 })),
        R.map(R.prop("d")),
        R.sort(R.ascend(R.prop("value")))
      )(data);

      let j =
        d3.bisector((d: LineDatum) => d.value).left(candidatePoints, yPos) - 1;

      if (j === -1) {
        j = 0;
      }

      const datum = R.prop(j, candidatePoints);

      const date = DateTime.fromJSDate(xPos)
        .toUTC()
        .toFormat(DEFAULT_DATE_FORMAT);
      const changeDate = DateTime.fromJSDate(datum.date)
        .toUTC()
        .toFormat(DEFAULT_DATE_FORMAT);

      const xVal = R.prop(xAttr, datum);

      const html = `
        <span class="text-sm">
          <p>
            <span class="text-gray-600">${R.prop(yAttr, datum)}</span>
          </p>
          <p>
            <span class="text-gray-600">${yLabel}: </span>
            <span class="text-gray-800 font-medium">${
              typeof valueFormatter === "function"
                ? valueFormatter(xVal)
                : valueFormatter.format(xVal)
            }</span>
          </p>
          <p>
            <span class="text-gray-600">Date: </span>
            <span class="text-gray-800 font-medium">${date}</span>
          </p>
          <p>
            <span class="text-gray-600">Last Change: </span>
            <span class="text-gray-800 font-medium">${changeDate}</span>
          </p>
        </span>
      `;

      Tooltip.html(html)
        .style("left", `${container.parentElement.offsetLeft + eventX + 10}px`)
        .style("top", `${container.parentElement.offsetTop + eventY + 10}px`);

      d3.select(container)
        .select("line.focus")
        .attr("x1", x(xPos))
        .attr("x2", x(xPos))
        .attr("y1", yMin)
        .attr("y2", yMax);

      d3.select(container)
        .select("circle.focus")
        .attr("r", 8)
        .attr("cx", x(xPos))
        .attr("cy", y(datum.value))
        .attr("fill", "white")
        .attr("stroke", Style.Color.Layout.Gray);

      d3.select(container)
        .selectAll("path")
        .attr("stroke-width", 2)
        .attr("opacity", 0.5);
      const thisLine = d3
        .select(container)
        .selectAll("path")
        .filter((d: LineData) => d.name === datum.name);

      thisLine.attr("stroke-width", 5).attr("opacity", 1);
    };
  };

  return { mouseover, mousemove, mouseleave };
};

const drawLine = (
  selection: d3.Selection<d3.BaseType, LineData, SVGGElement, LineData[]>,
  {
    props,
    scales,
  }: {
    props: LineChartComponentProps;
    scales: Scales;
  }
) => {
  const {
    extraProps: { colorAttr, colorScale, yAttr },
  } = props;
  const { x, y } = scales;
  return selection
    .attr("fill", "none")
    .attr("stroke", (d) =>
      colorScale(R.prop(colorAttr || yAttr, R.head(d.value)))
    )
    .attr("stroke-width", 2)
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("d", (d) =>
      d3
        .line<LineDatum>()
        .x((d) => {
          return x(d.date);
        })
        .y((d) => {
          return y(d.value);
        })
        .curve(LineGraphCurve)(d.value)
    );
};

const drawLines = (
  g: GroupSelection<LineData[]>,
  {
    props,
    scales,
  }: {
    props: LineChartComponentProps;
    scales: Scales;
  }
) => {
  const { data } = props;
  const lines = g.selectAll("path").data(data);

  lines.join(
    (enter) => {
      return drawLine(enter.append("path"), { props, scales });
    },
    (update) => {
      return drawLine(update, { props, scales });
    },
    (exit) => exit.remove()
  );
};

export interface LineData {
  name: string;
  value: LineDatum[];
}

export interface LineDatum {
  date: Date;
  name: string;
  value: number;
}

interface D3Props {
  colorScale: d3.ScaleOrdinal<string, string>;
  xAttr: string;
  xLabel: string;
  yAttr: string;
  yLabel: string;
  title: string;
  colorAttr?: string;
  formatter: ((value: number) => string) | Intl.NumberFormat;
  valueFormatter: ((value: number) => string) | Intl.NumberFormat;
  showLegend?: boolean;
}

interface Scales {
  x: d3.ScaleTime<number, number>;
  y: d3.ScaleLinear<number, number>;
}

export type LineChartComponentProps = D3ComponentProps<LineData[], D3Props>;
export type LineChartProps = Omit<
  D3ContainerProps<LineData[], Omit<D3Props, "computed">>,
  "width" | "height"
>;
export type LineChartData = LineData;

export class D3LineChart extends BaseD3Component<
  LineData[],
  LineChartComponentProps,
  Scales
> {
  width: number;
  height: number;
  eventHandlers: EventHandlers<unknown, LineChartComponentProps, Scales>;

  constructor(container: HTMLElement, props: LineChartComponentProps) {
    super(container, props);
    this.height = this.margin.top + this.margin.bottom + HEIGHT;
    this.eventHandlers = createTooltip(this.svg.node());
    this.init();
  }

  getBaseMargin() {
    return {
      top: 30,
      right: this.props.extraProps.showLegend ? 300 : 50,
      bottom: 50,
      left: 80,
    };
  }

  init() {
    const { title, xLabel, yLabel } = this.props.extraProps;

    Style.D3Text.Title(this.svg.selectAll(".axis"));
    Style.D3Text.Title(this.svg.select(".legend").attr("fill", "currentColor"));

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `title`)
        .attr("text-anchor", "middle")
        .attr("x", this.width / 2)
        .attr("y", 15)
        .text(title)
    );

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `x label`)
        .attr("fill", "currentColor")
        .attr("text-anchor", "middle")
        .attr("x", this.width / 2)
        .attr("y", this.height + 10)
        .text(xLabel)
    );

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `y label`)
        .attr("fill", "currentColor")
        .attr("text-anchor", "middle")
        .attr("transform", `translate(20, ${this.height / 2}) rotate(-90)`)
        .text(yLabel)
    );

    const focusElements = Style.D3Text.Title(this.svg.append("g"));

    focusElements
      .append("line")
      .attr("class", "focus hidden")
      .attr("stroke", "currentColor")
      .attr("stroke-dasharray", "1,5")
      .attr("stroke-width", "1");

    focusElements.append("circle").attr("class", "focus hidden");
  }

  getGraphElements(): {
    [key: string]: (
      container: GroupSelection<LineData[]>,
      {
        props,
        scales,
        ctx,
      }: {
        props: LineChartComponentProps;
        scales: Scales;
        ctx: BaseD3Component<LineData[], LineChartComponentProps, Scales>;
      }
    ) => void;
  } {
    return {
      lines: drawLines,
      legend: drawLegend({
        gutter: { left: 40, right: 20 },
        position: ({ gutter, ctx }) =>
          `translate(${ctx.width - ctx.margin.right + gutter.left}, ${
            ctx.margin.top
          })`,
      }),
    };
  }

  getGraphAxes(): {
    [key: string]: (
      g: GroupSelection<LineData[]>,
      { props, scales }: { props: LineChartComponentProps; scales: Scales }
    ) => void;
  } {
    return {
      x: (g, { scales: { x } }) => {
        const [start, end] = x.domain();
        const tickIntervals =
          d3.timeMonth.count(start, end) < 6
            ? d3.timeMonth
            : d3.timeYear.count(start, end) > 1
            ? d3.timeMonth.every(6)
            : d3.timeMonth.every(3);

        g.attr("transform", `translate(0,${this.height - this.margin.bottom})`)
          .call(
            d3
              .axisBottom(x)
              .ticks(tickIntervals)
              .tickFormat(d3.timeFormat("%b '%y"))
              .tickSize(0)
          )
          .call((g) => g.selectAll(".domain").remove());
      },
      y: (
        g,
        {
          props: {
            extraProps: { formatter },
          },
          scales: { y },
        }
      ) => {
        if (!formatter) return;
        Style.D3.Layout.Line(
          g
            .attr("transform", `translate(${this.margin.left},0)`)
            .call(
              d3
                .axisRight(y)
                .ticks(5)
                .tickFormat(
                  typeof formatter === "function" ? formatter : formatter.format
                )
                .tickSize(this.width - this.margin.left - this.margin.right)
            )
            .call((g) =>
              Style.D3Text.Title(g.selectAll(".tick text")).attr("x", `-40`)
            )
            .call((g) => g.selectAll(".domain").remove())
        );
      },
    };
  }

  createScales(props: LineChartComponentProps) {
    const {
      data,
      extraProps: { colorScale },
    } = props;
    const merged = R.pipe(
      R.values,
      R.map(R.prop("value")),
      R.flatten
    )(data) as number[];
    const [min, max] = d3.extent<number, number>(merged, R.prop("value"));

    return {
      x: d3
        .scaleTime<number, number>()
        .domain([
          d3.min(merged, R.prop("date")),
          d3.max(merged, R.prop("date")),
        ] as unknown as Date[])
        .range([this.margin.left, this.width - this.margin.right]),
      y: d3
        .scaleLinear()
        .domain([min * 0.98, max * 1.02])
        .range([this.height - this.margin.bottom, this.margin.top])
        .nice(),
      legend: colorScale,
    };
  }

  protected beforeUpdate(): void {
    const {
      data,
      width,
      extraProps: { xLabel, yLabel, yAttr, colorAttr },
    } = this.props;

    if (!data) {
      return;
    }

    const fixDates = R.map(
      R.over<LineData, LineDatum[]>(
        R.lensProp("value"),
        R.map(
          R.over(R.lensProp<LineDatum>("date"), (s: string) => new Date(s)) as (
            d: LineDatum
          ) => LineDatum
        )
      )
    );
    const setValue = R.map((d: LineData) =>
      R.over<LineData, LineDatum[]>(
        R.lensProp("value"),
        R.map(R.set(R.lensProp(yAttr as keyof LineDatum), R.prop(yAttr, d))),
        d
      )
    );
    const setColor = R.map((d: LineData) =>
      R.over(
        R.lensProp("value"),
        R.map(
          R.set(R.lensProp(colorAttr as keyof LineDatum), R.prop(colorAttr, d))
        ),
        d
      )
    );

    this.props.data = R.pipe(fixDates, setValue, setColor)(data);

    this.width = width;
    this.height = this.margin.top + this.margin.bottom + HEIGHT;

    this.svg
      .attr("viewBox", `0,0,${this.width},${this.height + 10}`)
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    this.svg
      .selectAll("text.title")
      .attr("x", this.width / 2)
      .attr("y", 15);

    this.svg
      .selectAll("text.x.label")
      .attr("x", this.width / 2)
      .attr("y", this.height + 10)
      .text(xLabel);

    this.svg
      .selectAll("text.y.label")
      .attr(
        "transform",
        `translate(20,${
          this.margin.top +
          (this.height - this.margin.top - this.margin.bottom) / 2
        }) rotate(-90)`
      )
      .text(yLabel);

    const { mouseover, mouseleave, mousemove } = this.eventHandlers;
    this.svg
      .on("mousemove", mousemove(this.props, this.createScales(this.props)))
      .on("mouseover", function () {
        mouseover();
      })
      .on("mouseout", function (e) {
        mouseleave(e);
      });
  }
}

const createChart = (ref: HTMLElement, props: LineChartComponentProps) => {
  return new D3LineChart(ref, props);
};

export const LineChart: React.FC<LineChartProps> = ({
  data,
  ...props
}): React.ReactElement => {
  return (
    <D3Container D3ComponentFactory={createChart} data={data} {...props} />
  );
};
