import * as R from "ramda";
import * as d3 from "d3";
import React from "react";

import * as Style from "style";
import {
  D3ComponentProps,
  D3Container,
  D3ContainerProps,
} from "components/D3Container";
import { BaseD3Component, GroupSelection } from "lib/d3/types";
import { Sample } from ".generated/models";

const margin = { top: 0, right: 5, bottom: 0, left: 5 };
const HEIGHT = 20;
const DEFAULT_COLOR = Style.Color.Chart.CornflowerBlue;

interface D3Props {
  colorScale: d3.ScaleOrdinal<string, string>;
}

interface Scales {
  x: d3.ScaleTime<number, number>;
  y: d3.ScaleLinear<number, number>;
}

type MetricTrendlinesProps = D3ComponentProps<Sample[], D3Props> & {
  color?: string;
};

const drawLine = (
  line: GroupSelection<Sample[]>,
  { props, scales }: { props: MetricTrendlinesProps; scales: Scales }
) => {
  const { x, y } = scales;

  const color = props.color || DEFAULT_COLOR;

  line.selectAll("path").remove();
  line
    .append("path")
    .datum(props.data)
    .attr("fill", "none")
    .attr("stroke", color)
    .attr("stroke-width", 1)
    .attr(
      "d",
      d3
        .line<Sample>()
        .x((d) => {
          return x(d.date as unknown as Date);
        })
        .y((d) => {
          return y(d.value);
        })
    );
};

const drawArea = (
  area: GroupSelection<Sample[]>,
  { props, scales }: { props: MetricTrendlinesProps; scales: Scales }
) => {
  const color = props.color || DEFAULT_COLOR;

  area.selectAll("path").remove();

  const { x, y } = scales;

  const areaFn = d3
    .area<Sample>()
    .x((d) => x(d.date as unknown as Date))
    .y1((d) => y(d.value))
    .y0(y(y.domain()[0]));

  area
    .append("path")
    .attr("opacity", "0.1")
    .attr("class", "pointer-events-none")
    .attr("fill", color)
    .attr("d", areaFn(props.data));
};

class MetricTrendlinesChart extends BaseD3Component<
  Sample[],
  MetricTrendlinesProps,
  Scales
> {
  width: number;
  height: number;

  getGraphAxes() {
    return {};
  }

  getBaseMargin() {
    return margin;
  }

  getGraphElements() {
    return {
      line: drawLine,
      area: drawArea,
    };
  }

  constructor(container: HTMLElement, props: MetricTrendlinesProps) {
    super(container, props);
    this.width = props.width;
    this.height = margin.top + margin.bottom + HEIGHT;
  }

  createScales(props: MetricTrendlinesProps) {
    return {
      x: d3
        .scaleTime()
        .domain(d3.extent(props.data, (d: Sample) => d.date as unknown as Date))
        .range([margin.left, props.width - margin.right]),
      y: d3
        .scaleLinear()
        .domain(d3.extent(props.data, (d: Sample) => d.value))
        .range([this.height - margin.bottom, margin.top]),
    };
  }

  beforeUpdate() {
    this.props.data = R.map(
      R.over(
        R.lensProp("date" as keyof unknown),
        (s: string) => new Date(s) as unknown as string
      ),
      this.props.data
    );

    this.width = this.props.width;

    this.svg.attr("viewBox", `0,0,${this.width},${this.height}`);
  }
}

const createChart = (ref: HTMLElement, props: MetricTrendlinesProps) => {
  return new MetricTrendlinesChart(ref, props);
};

export const MetricTrendlines: React.FC<
  Omit<D3ContainerProps<Sample[], D3Props>, "width" | "height"> & {
    color?: string;
  }
> = (props) => {
  return <D3Container D3ComponentFactory={createChart} {...props} />;
};
