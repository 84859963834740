import * as d3 from "d3";
import * as R from "ramda";
import React from "react";

import * as Style from "style";
import {
  D3ComponentProps,
  D3Container,
  D3ContainerProps,
} from "components/D3Container";
import { getLongestTextElementInSelection, wrap } from "./util/wrap";
import { Transitions } from "./util/transitions";
import { BaseD3Component, EventHandlers, GroupSelection } from "lib/d3";

const barHeight = 25;
const MIN_HEIGHT = 400;

const isBad = R.either(R.isNil, Number.isNaN);

type Data = Record<string, unknown>;

interface D3Props {
  colorScale?: d3.ScaleOrdinal<string, string>;
  colorAttr?: string;
  disableTransitions?: boolean;
  xLabel: string;
  xAttr: string;
  xFormat: ((value: number) => string) | Intl.NumberFormat;
  zFormat: ((value: number) => string) | Intl.NumberFormat;
  xValueFormatter: ((value: number) => string) | Intl.NumberFormat;
  zValueFormatter: ((value: number) => string) | Intl.NumberFormat;
  yLabel?: string;
  yAttr: string | ((d: Data) => string);
  zLabel: string;
  zAttr: string;
}

const getValue = R.curry((attr: string | ((val: Data) => string), d: Data) => {
  if (typeof attr === "function") {
    return attr(d);
  }
  return R.prop(attr, d);
});

const createTooltip = (
  container: SVGSVGElement
): EventHandlers<Data[], BarChartComponentProps, Scales> => {
  const Tooltip = d3
    .select(container.parentNode as d3.BaseType)
    .append("div")
    .attr(
      "class",
      "hidden timeline-tooltip absolute bg-white border border-gray-300 rounded text-gray-600 opacity-75 p-3 mb-0 z-10 pointer-events-none"
    );

  const showTooltip = () => Tooltip.node().classList.remove("hidden");
  const hideTooltip = () => Tooltip.node().classList.add("hidden");

  const mouseover = function () {
    if (Tooltip.node().innerHTML !== "") {
      showTooltip();
    }
  };

  const mouseleave = function (e: MouseEvent) {
    if (e.target === e.currentTarget) {
      hideTooltip();
    }
  };

  const mousemove = function (props: BarChartComponentProps, scales: Scales) {
    const { x, y } = scales;
    const {
      data,
      extraProps: {
        xAttr,
        xLabel,
        yAttr,
        zAttr,
        zLabel,
        zValueFormatter,
        xValueFormatter,
      },
    } = props;

    return function (e: MouseEvent) {
      const [eventX, eventY] = d3.pointer(e);

      const [xMin, xMax] = x.range();
      const [yMin, yMax] = y.range();

      if (eventX < xMin || eventX > xMax || eventY < yMin || eventY > yMax) {
        hideTooltip();
        return;
      }
      const thisBar = R.prop(Math.floor((eventY - 50) / y.step()), data);

      const xVal = R.prop(xAttr, thisBar) as number;
      const yVal = getValue(yAttr, thisBar) as string;
      const zVal = R.prop(zAttr, thisBar) as number;

      if (!xVal || !yVal) {
        hideTooltip();
        return;
      }

      const zInfo = !R.isNil(zVal)
        ? `
				<p>
					<span class="text-gray-800 font-medium">
						${zLabel}: ${
            typeof zValueFormatter === "function"
              ? zValueFormatter(zVal)
              : zValueFormatter.format(zVal)
          }
					</span>
				</p>
        `
        : "";

      const html = `
        <span class="text-sm">
          <p>
            <span class="text-gray-600">${yVal}</span>
          </p>
          <p>
            <span class="text-gray-800 font-medium">${xLabel}: ${
        typeof xValueFormatter === "function"
          ? xValueFormatter(xVal)
          : xValueFormatter.format(xVal)
      }</span>
          </p>
          ${zInfo}
        </span>
      `;

      Tooltip.html(html)
        .style("left", `${container.parentElement.offsetLeft + eventX}px`)
        .style("top", `${container.parentElement.offsetTop + eventY}px`);
    };
  };

  return { mouseover, mousemove, mouseleave };
};

const drawBar = (
  g: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>,
  { props, scales }: { props: BarChartComponentProps; scales: Scales }
) => {
  const {
    extraProps: { colorAttr, xAttr, disableTransitions },
  } = props;
  const { x, y, color } = scales;
  const addWidth = (d) => {
    const width = x(R.prop(xAttr, d)) - x(0);
    return width;
  };
  return g
    .attr("x", x(0))
    .attr("y", (_d, i) => y(i))
    .attr("fill", (d) => {
      return color((colorAttr && R.prop(colorAttr, d)) || xAttr);
    })
    .on("mouseover", function () {
      d3.select(this).attr("fill", Style.Color.Layout.Gray);
    })
    .on("mouseleave", function () {
      d3.select(this).attr("fill", (d) =>
        color((colorAttr && R.prop(colorAttr, d)) || xAttr)
      );
    })
    .attr("height", y.bandwidth())
    .call((g) => {
      if (!disableTransitions) {
        return g.transition(Transitions.Bouncy()).attr("width", addWidth);
      }
      g.attr("width", addWidth);
    });
};

interface Scales {
  x: d3.ScaleLinear<number, number>;
  y: d3.ScaleBand<number>;
  z: d3.ScaleLinear<number, number>;
  color: d3.ScaleOrdinal<string, string>;
}

type BarChartComponentProps = D3ComponentProps<Data[], D3Props>;
export type BarChartProps = Omit<
  D3ContainerProps<Data[], D3Props>,
  "width" | "height"
>;

export class D3BarChart extends BaseD3Component<
  Data[],
  BarChartComponentProps,
  Scales
> {
  height: number;
  eventHandlers: EventHandlers<Data[], BarChartComponentProps, Scales>;

  constructor(container: HTMLElement, props: BarChartComponentProps) {
    super(container, props);
    this.init();
  }

  getGraphAxes() {
    return {
      x: (
        g: GroupSelection<unknown>,
        {
          props: {
            extraProps: { xFormat },
          },
          scales,
        }: { props: BarChartComponentProps; scales: Scales }
      ) => {
        const margin = this.margin;
        g.attr("transform", `translate(0,${margin.top})`).call(
          d3
            .axisTop(scales.x)
            .ticks(3)
            .tickSize(0)
            .tickFormat(
              typeof xFormat === "function" ? xFormat : xFormat.format
            )
        );
        g.call((g) => g.selectAll(".domain").remove());
      },
      y: (
        g: GroupSelection<unknown>,
        {
          props: {
            data,
            width,
            extraProps: { yLabel, yAttr },
          },
          scales,
        }: { props: BarChartComponentProps; scales: Scales }
      ) => {
        const margin = this.margin;
        let maxLength = margin.left;
        Style.D3.Layout.Line(
          g
            .attr("text-anchor", "start")
            .call(
              d3
                .axisRight(scales.y)
                .tickFormat((i: number) => {
                  return getValue(yAttr, data[i]) as string;
                })
                .tickSize(width)
            )
            .call((g) => {
              maxLength =
                40 +
                getLongestTextElementInSelection(g.selectAll(".tick text"));
            })
            .call((g) =>
              Style.D3Text.Title(g.selectAll(".tick text"))
                .attr("x", `-${maxLength - (yLabel ? 30 : 0)}`)
                .each(wrap(maxLength, 5))
            )
            .call(() => (margin.left = maxLength))
            .attr("transform", `translate(${margin.left},0)`)
            .call((g) => g.selectAll(".domain").remove())
        );
      },
      z: (
        g: GroupSelection<unknown>,
        {
          props: {
            extraProps: { zFormat },
          },
          scales,
        }: { props: BarChartComponentProps; scales: Scales }
      ) => {
        const margin = this.margin;
        g.attr("transform", `translate(0,${this.height - margin.bottom})`).call(
          d3
            .axisBottom(scales.z)
            .ticks(3)
            .tickSize(0)
            .tickFormat(
              typeof zFormat === "function" ? zFormat : zFormat.format
            )
        );

        g.call((g) => g.selectAll(".domain").remove());
      },
    };
  }

  getGraphElements() {
    return {
      bars: (
        g: GroupSelection<Data[]>,
        { props, scales }: { props: BarChartComponentProps; scales: Scales }
      ) => {
        const { data } = props;

        const xData = g.selectAll("rect").data(data);

        xData.join(
          (enter) =>
            Style.D3.GraphElements.Bar(enter.append("rect")).call((enter) =>
              drawBar(enter, {
                props,
                scales,
              })
            ) as d3.Selection<d3.BaseType, Data, SVGGElement, Data[]>,
          (update) =>
            update.call((update) => drawBar(update, { props, scales }))
        );
      },
      dots: (
        g: GroupSelection<Data[]>,
        { props, scales }: { props: BarChartComponentProps; scales: Scales }
      ) => {
        const { data } = props;
        const { y, z, color } = scales;
        const { disableTransitions, zAttr } = props.extraProps;
        const zData = g
          .attr("fill", color(zAttr))
          .selectAll("path")
          .data(data.filter((d) => !isBad(d[zAttr])));

        zData.join(
          (enter) =>
            enter
              .append("path")
              .call((enter) =>
                enter
                  .attr("d", d3.symbol().type(d3.symbolCircle).size(50))
                  .attr(
                    "transform",
                    (d, i) =>
                      `translate(${z(R.prop(zAttr, d) as number)},${
                        y(i) + y.bandwidth() / 2
                      })`
                  )
              ),
          (update) =>
            update
              .attr(
                "transform",
                (d, i) =>
                  `translate(${z(R.prop(zAttr, d) as number)},${
                    y(i) + y.bandwidth() / 2
                  })`
              )
              .attr("opacity", 0)
              .call((update) => {
                if (!disableTransitions) {
                  update.transition(Transitions.Standard()).attr("opacity", 1);
                }
              })
        );
      },
    };
  }

  getBaseMargin() {
    return { top: 50, right: 20, bottom: 50, left: 50 };
  }

  init() {
    const {
      width,
      extraProps: { xLabel, yLabel, zLabel },
    } = this.props;

    this.eventHandlers = createTooltip(this.svg.node());
    this.height = Math.max(
      Math.ceil(10.1 * barHeight) + this.margin.top + this.margin.bottom,
      MIN_HEIGHT
    );

    const { mouseover, mouseleave } = this.eventHandlers;
    Style.D3Text.Title(this.svg.select("g.x.axis"));
    Style.D3Text.Title(this.svg.select("g.y.axis"));
    Style.D3Text.Title(this.svg.select("g.z.axis"));

    this.svg.on("mouseleave", mouseleave);
    this.svg.on("mouseover", mouseover);
    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `x label`)
        .attr("fill", "currentColor")
        .attr("text-anchor", "middle")

        .attr("x", width / 2)
        .attr("y", 20)
        .text(xLabel)
    );

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `y label`)
        .attr("fill", "currentColor")
        .attr("text-anchor", "middle")
        .attr("transform", `translate(20, ${this.height / 2}) rotate(-90)`)
        .text(yLabel)
    );

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `z label`)
        .attr("text-anchor", "middle")
        .attr("fill", "currentColor")
        .attr("x", width / 2)
        .attr("y", this.height)
        .text(zLabel)
    );
  }

  createScales(props: BarChartComponentProps): Scales {
    const {
      data,
      width,
      extraProps: { colorScale, xAttr, zAttr },
    } = props;
    const margin = this.margin;
    return {
      x: d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => R.prop(xAttr, d) as number)])
        .range([
          Math.max(0, margin.left),
          Math.max(margin.left, width - margin.right),
        ]),
      y: d3
        .scaleBand<number>()
        .domain(d3.range(data.length))
        .rangeRound([margin.top, this.height - margin.bottom])
        .padding(0.5),
      z: d3
        .scaleLinear()
        .domain([0, d3.max(data, (d: Data) => R.prop(zAttr, d) as number)])
        .range([
          Math.max(0, margin.left),
          Math.max(margin.left, width - margin.right),
        ]),
      color:
        colorScale ||
        d3
          .scaleOrdinal<string, string>()
          .range(Style.ChartColors)
          .domain([props.extraProps.xAttr, props.extraProps.zAttr]),
    };
  }

  beforeUpdate() {
    const {
      data,
      width,
      extraProps: { xLabel, yLabel, zLabel, xAttr, zAttr },
    } = this.props;
    const margin = this.margin;

    this.height = Math.max(
      barHeight * data.length + margin.top + margin.bottom,
      MIN_HEIGHT
    );
    this.svg
      .attr("viewBox", `0,0,${width},${this.height}`)
      .attr("width", width)
      .attr("height", this.height)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    const scales = this.createScales(this.props);
    this.svg
      .selectAll("text.x.label")
      .attr("x", margin.left + (width - margin.left) / 2)
      .attr("y", 20)
      .attr("fill", scales.color(xAttr))
      .text(xLabel);

    this.svg.selectAll(".x.axis text").attr("fill", scales.color(xAttr));

    this.svg
      .selectAll("text.y.label")
      .attr(
        "transform",
        `translate(20,${
          margin.top + (this.height - margin.top - margin.bottom) / 2
        }) rotate(-90)`
      )
      .text(yLabel);

    this.svg
      .selectAll("text.z.label")
      .attr("x", margin.left + (width - margin.left) / 2)
      .attr("y", this.height - 6)
      .attr("fill", scales.color(zAttr))
      .text(zLabel || "");

    this.svg.selectAll(".z.axis text").attr("fill", scales.color(zAttr));

    this.svg.on("mousemove", this.eventHandlers.mousemove(this.props, scales));
  }
}

const createBarChart = (ref: HTMLElement, props: BarChartComponentProps) => {
  return new D3BarChart(ref, props);
};

export const BarChart: React.FC<BarChartProps> = ({
  onInitialize,
  ...props
}) => {
  return (
    <D3Container
      D3ComponentFactory={createBarChart}
      onInitialize={onInitialize}
      {...props}
    />
  );
};
