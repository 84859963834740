import {
  ComputedProps,
  PaybandChartComponentProps,
  Params,
  BenchmarkType,
} from "./types";

import * as R from "ramda";

import {
  BASE_BENCHMARK_FIELDS,
  BASE_BENCHMARK_PATHS,
  BENCHMARK_FIELDS,
  BENCHMARK_PATHS,
  HOURLY_EQUIVALENTS,
} from "./constant";
import {
  deriveBenchmarksFromData,
  derivePaybandsFromData,
  getFormatter,
  getIsHourly,
  getYValue,
} from "./util";
import { computeRegions } from "./data/regions";
import { groupData } from "./data/groups";
import { HydratedEmployee } from ".generated/models";

export function getPayAttributes(props: PaybandChartComponentProps) {
  const {
    extraProps: { xAttr, xRangeMinAttr, xRangeMaxAttr },
  } = props;
  let payAttr = xAttr as keyof HydratedEmployee;
  let paybandMaxAttr = xRangeMaxAttr as keyof HydratedEmployee;
  let paybandMinAttr = xRangeMinAttr as keyof HydratedEmployee;

  const isHourly = getIsHourly(props);

  if (isHourly && R.has(payAttr, HOURLY_EQUIVALENTS)) {
    payAttr = R.propOr(payAttr, payAttr, HOURLY_EQUIVALENTS);
    paybandMinAttr = R.propOr(
      paybandMinAttr,
      paybandMinAttr,
      HOURLY_EQUIVALENTS
    );
    paybandMaxAttr = R.propOr(
      paybandMaxAttr,
      paybandMaxAttr,
      HOURLY_EQUIVALENTS
    );
  }

  return { payAttr, paybandMinAttr, paybandMaxAttr };
}

// TODO: take a function here to do the exchange rates
export function getBenchmarkAccessor(yKeyFn) {
  const pathAccessors = R.map((f: string[]) => R.path(f), BENCHMARK_PATHS);
  const basePathAccessors = R.map(
    (f: string[]) => R.path(f),
    BASE_BENCHMARK_PATHS
  );

  const spec = R.zipObj(BENCHMARK_FIELDS, pathAccessors);
  const baseCurrencySpec = R.zipObj(BASE_BENCHMARK_FIELDS, basePathAccessors);

  return R.applySpec({ y: yKeyFn, ...R.mergeRight(spec, baseCurrencySpec) });
}

export function getBenchmarkFields(props: PaybandChartComponentProps) {
  return props.extraProps.benchmarkType === BenchmarkType.Local
    ? BENCHMARK_FIELDS
    : BASE_BENCHMARK_FIELDS;
}

export const getComputedProps = (
  // scales is dependent on the computed props so we omit it here.
  params: Omit<Params, "scales">
): ComputedProps => {
  const {
    props,
    ctx: { expandedState },
  } = params;
  const {
    data,
    extraProps: { getPaybands, hideBenchmarks, currency, yAttr, yKeyAttr },
  } = props;

  const { payAttr, paybandMinAttr, paybandMaxAttr } = getPayAttributes(props);

  const getY = getYValue(yAttr);
  const getYKey = getYValue(yKeyAttr || yAttr);
  const toBenchmark = getBenchmarkAccessor(getYKey);

  const benchmarks = hideBenchmarks
    ? []
    : deriveBenchmarksFromData(toBenchmark, props);

  const defaultCurrency = R.pipe(
    R.head,
    R.propOr(currency, "currency")
  )(data) as string;

  const baseCurrency: string = currency;

  const formatter = getFormatter(defaultCurrency, baseCurrency, payAttr);

  const paybandFn = getPaybands || derivePaybandsFromData;
  const paybands = paybandFn({
    yKey: getYKey,
    y: yAttr,
    min: paybandMinAttr,
    max: paybandMaxAttr,
  })(props.data);

  const regions = computeRegions({
    paybands: R.pipe(
      R.map(
        R.applySpec({
          y: getYKey,
          min: R.prop(paybandMinAttr),
          max: R.prop(paybandMaxAttr),
        })
      ),
      R.filter(
        R.where({ min: R.complement(R.isNil), max: R.complement(R.isNil) })
      )
    )(paybands),
    benchmarks,
    benchmarkFields: getBenchmarkFields(props),
  });

  const groupedData = groupData(props);

  return {
    benchmarks,
    expandedState,
    formatter,
    getYValue: getY,
    getYKey,
    groupedData,
    paybands,
    payAttr,
    paybandMinAttr,
    paybandMaxAttr,
    hourly: getIsHourly(props),
    regions,
  };
};
