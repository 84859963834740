import * as d3 from "d3";

export const format =
  (formatter: Intl.NumberFormat) =>
  (value: number): string => {
    const parts = formatter.formatToParts(value);
    const curSymbol = parts.find((p) => p.type === "currency")?.value || "";
    const d3SiValue = d3.format("~s")(value).replace(/G$/, "B");
    return `${curSymbol}${d3SiValue}`;
  };
