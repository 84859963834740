import React from "react";
import * as d3 from "d3";
import * as R from "ramda";

import * as Style from "style";
import { D3ComponentProps, D3Container } from "components/D3Container";
import { getLongestTextElementInSelection, wrap } from "./util/wrap";
import { drawRoundedRectangle, RectangleCorners } from "./util/curves";
import { BaseD3Component, GroupSelection } from "lib/d3";
import { FormatFunction } from "components/Plan/Report/Builder/type";
import { drawLegend } from "./util/legend";

const BAR_HEIGHT = 25;

const gt0 = (x: number) => Math.max(0, x);

const groupAndAssignTotals = R.curry((attr: string) => {
  return R.pipe(
    R.groupBy(R.prop<string>("name")),
    R.map(
      R.reduce(
        (acc: Record<string, unknown>, obj: StackedBarChartData) =>
          R.set(R.lensProp(R.prop("color", obj)), R.prop(attr, obj), acc),
        {}
      )
    ) as any,
    R.mapObjIndexed((d: Record<string, number>) =>
      R.set(R.lensProp("total"), R.sum(R.values(d)), d)
    ),
    R.toPairs,
    R.map(([k, v]) => R.set(R.lensProp("name" as keyof unknown), k, v)),
    R.sort(R.descend(R.prop("total")))
  );
});

const determineRoundedCorners = (
  d: d3.SeriesPoint<{ [key: string]: number }>
) => {
  const [start, end] = d;
  let flags = RectangleCorners.None;
  if (start === 0)
    flags |= RectangleCorners.TopLeft | RectangleCorners.BottomLeft;
  if (Math.abs(end - d.data.total) < 0.01)
    flags |= RectangleCorners.TopRight | RectangleCorners.BottomRight;

  return drawRoundedRectangle(flags);
};

const drawBar = (
  selection: d3.Selection<d3.BaseType, StackedDatum, SVGGElement, StackedDatum>,
  { extraProps: { xValueFormatter } }: StackedBarChartComponentProps,
  scales: Scales
): d3.Selection<d3.BaseType, StackedDatum, SVGGElement, StackedDatum> => {
  const { x, y } = scales;
  selection
    .data((d) => d)
    .join("path")
    .attr("d", (d) => {
      // fixes some console errors
      if (Number.isNaN(d[1])) return undefined;

      return d3
        .area<number>()
        .x((_d: number) => gt0(x(_d)))
        .y0(gt0(y(d.data.name as unknown as string)))
        .y1(gt0(y(d.data.name as unknown as string)) + y.bandwidth())
        .curve(determineRoundedCorners(d))(d);
    })
    .append("title")
    .text((d) => {
      const value = d[1] - d[0];
      if (Number.isNaN(value)) {
        return;
      }

      const valueText =
        typeof xValueFormatter === "function"
          ? xValueFormatter(value)
          : xValueFormatter.format(value);
      return valueText;
    });
  return selection;
};

const drawBars = (
  group: GroupSelection<StackedBarChartData[]>,
  { props, scales }: { props: StackedBarChartComponentProps; scales: Scales }
) => {
  const bars = (group as unknown as GroupSelection<StackedDatum>)
    .selectAll("g")
    .data(props.extraProps.stackData);
  const { colorScale } = props.extraProps;

  return bars.join(
    (enter) =>
      drawBar(
        enter
          .append("g")
          .attr("fill", (d) => {
            return colorScale(R.prop("key", d));
          })
          .selectAll("path"),
        props,
        scales
      ),
    (update) =>
      drawBar(
        update.selectAll("path") as d3.Selection<
          d3.BaseType,
          StackedDatum,
          SVGGElement,
          undefined
        >,
        props,
        scales
      ),
    (exit) => exit.remove()
  );
};

interface Scales {
  x: d3.ScaleLinear<number, number>;
  y: d3.ScaleBand<string>;
  legend: d3.ScaleOrdinal<string, string>;
}

export type StackedBarChartData = {
  name: string;
  color: string;
} & { [key: string]: number };

type StackedData = StackedDatum[];
type StackedDatum = d3.Series<{ [key: string]: number }, string>;

interface ExclusiveStackedBarChartProps {
  colorScale: d3.ScaleOrdinal<string, string>;
  xAttr: string;
  xLabel: string;
  xTickFormatter: FormatFunction;
  xValueFormatter: FormatFunction | Intl.NumberFormat;
  // these are computed by the component and not passed in -- better way to represent this in type system?
  stackData?: StackedData;
  showLegend?: boolean;
}

type StackedBarChartComponentProps = D3ComponentProps<
  StackedBarChartData[],
  ExclusiveStackedBarChartProps
>;
export type StackedBarChartProps = Omit<
  StackedBarChartComponentProps,
  "width" | "height"
>;

export class D3StackedBarChart extends BaseD3Component<
  StackedBarChartData[],
  StackedBarChartComponentProps,
  Scales
> {
  height: number;

  constructor(container: HTMLElement, props: StackedBarChartComponentProps) {
    super(container, props);
    this.init();
  }

  getBaseMargin() {
    return {
      top: 10,
      right: this.props.extraProps.showLegend ? 120 : 20,
      bottom: this.props.extraProps.showLegend ? 200 : 50,
      left: 0,
    };
  }

  getGraphAxes() {
    const {
      extraProps: { xTickFormatter },
    } = this.props;
    return {
      x: (g: GroupSelection<unknown>, { scales }: { scales: Scales }) =>
        g
          .attr("transform", `translate(0,${this.height - this.margin.bottom})`)
          .call(
            d3
              .axisBottom(scales.x)
              .ticks(4)
              .tickFormat(xTickFormatter)
              .tickSize(0)
          )
          .call((g) => g.selectAll(".domain").remove()),
      y: (g: GroupSelection<unknown>, { scales }: { scales: Scales }) => {
        let maxLength = this.margin.left;
        Style.D3.Layout.Line(
          g
            .attr("transform", `translate(${this.margin.left},0)`)
            .attr("text-anchor", "start")
            .call(
              d3
                .axisRight(scales.y)
                .tickSize(this.width - this.margin.right - this.margin.left)
            )
            .call((g) => {
              maxLength =
                40 +
                getLongestTextElementInSelection(g.selectAll(".tick text"));
            })
            .call((g) =>
              Style.D3Text.Title(g.selectAll(".tick text"))
                .attr("x", `-${maxLength}`)
                .each(wrap(maxLength, 5))
            )
            .call((g) => g.selectAll(".tick title").remove())
            .call((g) => g.selectAll(".domain").remove())
            .call(() => (this.margin.left = maxLength))
            .attr("transform", `translate(${this.margin.left},0)`)
            .call((g) =>
              g
                .selectAll(".tick")
                .append("title")
                .text(R.identity as (val: string) => string)
            )
            .call((g) =>
              // fix the width of tick lines after we possibly adjusted the left margin
              g
                .selectAll(".tick line")
                .attr("x2", this.width - this.margin.right - this.margin.left)
            )
        );
      },
    };
  }

  getGraphElements(): {
    [key: string]: (
      container: GroupSelection<StackedBarChartData[]>,
      {
        props,
        scales,
        ctx,
      }: {
        props: StackedBarChartComponentProps;
        scales: Scales;
        ctx: BaseD3Component<
          StackedBarChartData[],
          StackedBarChartComponentProps,
          Scales
        >;
      }
    ) => void;
  } {
    return {
      bars: drawBars,
      legend: drawLegend({
        gutter: { left: 40, right: 20 },
        position: ({ gutter, ctx }) =>
          `translate(${ctx.width - ctx.margin.right + gutter.left}, ${
            ctx.margin.top
          })`,
      }),
    };
  }

  init() {
    Style.D3Text.Title(this.svg.select("g.x.axis"));
    Style.D3Text.Title(this.svg.select("g.y.axis"));
    Style.D3Text.Title(this.svg.select("g.bars").attr("fill", "currentColor"));

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `x label fill-current`)
        .attr("text-anchor", "middle")
        .attr("x", this.props.width / 2)
        .attr("y", this.props.height - 6)
        .text(this.props.extraProps.xLabel)
    );

    Style.D3Text.Title(this.svg.select(".legend").attr("fill", "currentColor"));
  }

  createScales(props: StackedBarChartComponentProps) {
    const withTotal = groupAndAssignTotals(props.extraProps.xAttr)(props.data);
    const xMax = R.reduce(
      R.max,
      -Infinity,
      R.map(R.prop("total"), withTotal)
    ) as number;
    return {
      x: d3
        .scaleLinear()
        .domain([0, xMax])
        .range([this.margin.left, props.width - this.margin.right]),
      y: d3
        .scaleBand()
        .domain(R.pluck("name", props.data))
        .rangeRound([this.margin.top, this.height - this.margin.bottom])
        .padding(0.5),
      legend: props.extraProps.colorScale,
    };
  }

  beforeUpdate() {
    const {
      data,
      width,
      extraProps: { xAttr },
    } = this.props;

    const rows = R.map(R.prop("name"), R.uniqBy(R.prop("name"), data));
    const columns = R.map(R.prop("color"), R.uniqBy(R.prop("color"), data));
    this.width = width;
    this.height =
      this.margin.top + this.margin.bottom + BAR_HEIGHT * rows.length;

    const stackGenerator = d3.stack().keys(columns);
    const withTotals = groupAndAssignTotals(xAttr)(data) as Iterable<{
      [key: string]: number;
    }>;
    const stack = stackGenerator(withTotals);
    stack.map((d) =>
      d.forEach((v) =>
        R.set(R.lensProp("key" as keyof unknown), d.key as unknown as number, v)
      )
    );

    this.props.extraProps.stackData = stack;

    this.svg
      .attr("viewBox", `0,0,${width},${this.height}`)
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    this.svg
      .selectAll("text.x.label")
      .attr("x", width / 2)
      .attr("y", this.height - 6);
  }
}

const createChart = (
  ref: HTMLElement,
  props: StackedBarChartComponentProps
) => {
  return new D3StackedBarChart(ref, props);
};

export const StackedBarChart: React.FC<StackedBarChartProps> = (props) => {
  return <D3Container D3ComponentFactory={createChart} {...props} />;
};
