import * as R from "ramda";

import { intersection, union } from "@milio/lib/util/set";
import { HydratedEmployee } from ".generated/models";

export class RegionRectangle {
  constructor(
    public readonly x1: number,
    public readonly x2: number,
    public readonly y1: number,
    public readonly y2: number,
    public readonly rectangleType: RectangleType
  ) {}

  get width() {
    return this.x2 - this.x1;
  }

  get height() {
    return this.y2 - this.y1;
  }

  intersects(other: RegionRectangle): boolean {
    return !(
      other.x1 > this.x1 + this.width ||
      other.x1 + other.width < this.x1 ||
      other.y1 > this.y1 + this.height ||
      other.y1 + other.height < this.y1
    );
  }

  getMinimumBoundingRectangle(other: RegionRectangle): RegionRectangle | null {
    if (!this.intersects(other)) return null;

    return new RegionRectangle(
      Math.max(Math.max(0, this.x1), Math.max(0, other.x1)),
      Math.min(this.x2, other.x2),
      Math.max(Math.max(0, this.y1), Math.max(0, other.y1)),
      Math.min(this.y2, other.y2),
      this.rectangleType | other.rectangleType
    );
  }

  get label() {
    const labels = [];
    if (this.rectangleType & RectangleType.BelowBand) {
      labels.push("Below Pay Band");
    }
    if (this.rectangleType & RectangleType.InBand) {
      labels.push("In Pay Band");
    }
    if (this.rectangleType & RectangleType.AboveBand) {
      labels.push("Above Pay Band");
    }
    if (this.rectangleType & RectangleType.BenchmarkLessThan25) {
      labels.push("Less than 25th percentile");
    }
    if (this.rectangleType & RectangleType.BenchmarkFrom25To49) {
      labels.push("From 25-49th percentile");
    }
    if (this.rectangleType & RectangleType.BenchmarkFrom50To74) {
      labels.push("From 50-74th percentile");
    }
    if (this.rectangleType & RectangleType.BenchmarkGreaterThanOrEqualTo75) {
      labels.push("Greater than 75th percentile");
    }

    return labels.join(", ");
  }
}

interface Payband {
  y: string;
  min: number | null;
  max: number | null;
}

export interface Benchmark extends Partial<HydratedEmployee> {
  y?: string;
}

export interface ComputeRegionProps {
  paybands: Payband[];
  benchmarks: Benchmark[];
  benchmarkFields: string[];
}

export enum RectangleType {
  BelowBand = 1 << 0,
  InBand = 1 << 1,
  AboveBand = 1 << 2,
  BenchmarkLessThan25 = 1 << 3,
  BenchmarkFrom25To49 = 1 << 4,
  BenchmarkFrom50To74 = 1 << 5,
  BenchmarkGreaterThanOrEqualTo75 = 1 << 6,
}

function* toPaybandRectangles(
  payband: Payband
): IterableIterator<RegionRectangle> {
  yield new RegionRectangle(
    0,
    payband.min - Number.EPSILON,
    -Infinity,
    Infinity,
    RectangleType.BelowBand
  );
  yield new RegionRectangle(
    payband.min,
    payband.max - Number.EPSILON,
    -Infinity,
    Infinity,
    RectangleType.InBand
  );
  yield new RegionRectangle(
    payband.max,
    Infinity,
    -Infinity,
    Infinity,
    RectangleType.AboveBand
  );
}

function* toBenchmarkRectangles(
  benchmark: Benchmark,
  fields: string[]
): IterableIterator<RegionRectangle> {
  const [field25, field50, field75] = fields;
  yield new RegionRectangle(
    -Infinity,
    Infinity,
    0,
    benchmark[field25] - Number.EPSILON,
    RectangleType.BenchmarkLessThan25
  );
  yield new RegionRectangle(
    -Infinity,
    Infinity,
    benchmark[field25],
    benchmark[field50] - Number.EPSILON,
    RectangleType.BenchmarkFrom25To49
  );
  yield new RegionRectangle(
    -Infinity,
    Infinity,
    benchmark[field50],
    benchmark[field75] - Number.EPSILON,
    RectangleType.BenchmarkFrom50To74
  );
  yield new RegionRectangle(
    -Infinity,
    Infinity,
    benchmark[field75],
    Infinity,
    RectangleType.BenchmarkGreaterThanOrEqualTo75
  );
}

export const computeRegions = ({
  paybands,
  benchmarks,
  benchmarkFields,
}: ComputeRegionProps) => {
  const hasPaybands = new Set(R.pluck("y", paybands));
  const hasBenchmarks = new Set(R.pluck("y", benchmarks));

  const paybandIndex = R.indexBy(R.prop("y"), paybands);
  const benchmarkIndex = R.indexBy(R.prop("y"), benchmarks);

  const hasBoth = intersection(hasPaybands, hasBenchmarks);
  const all = union(hasPaybands, hasBenchmarks);

  const getPaybandRectangles: (key: string) => RegionRectangle[] = (
    key: string
  ) => Array.from(toPaybandRectangles(paybandIndex[key]));

  const getBenchmarkRectangles: (key: string) => RegionRectangle[] = (
    key: string
  ) => Array.from(toBenchmarkRectangles(benchmarkIndex[key], benchmarkFields));

  const getIntersectionRegions = R.pipe(
    (key: string) =>
      R.xprod(getPaybandRectangles(key), getBenchmarkRectangles(key)),
    R.map(([r1, r2]) => r1.getMinimumBoundingRectangle(r2)),
    R.reject(R.isNil)
  ) as (key: string) => RegionRectangle[];

  return R.reduce(
    (acc: { [key: string]: RegionRectangle[] }, key: string) => {
      const value = hasBoth.has(key)
        ? getIntersectionRegions(key)
        : hasPaybands.has(key)
        ? getPaybandRectangles(key)
        : hasBenchmarks.has(key)
        ? getBenchmarkRectangles(key)
        : [];
      return R.set(R.lensProp(key), value, acc);
    },
    {},
    Array.from(all)
  );
};
