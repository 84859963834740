import * as d3 from "d3";
import * as R from "ramda";
import React from "react";

import * as Style from "style";
import {
  D3ComponentProps,
  D3Container,
  D3ContainerProps,
} from "components/D3Container";
import { BaseD3Component, EventHandlers, GroupSelection } from "lib/d3";
import { Transitions } from "./util/transitions";
import { drawLegend } from "./util/legend";
import { PieArcDatum } from "d3";

const MAX_HEIGHT = 400;

const createTooltip = (
  container: SVGSVGElement
): EventHandlers<d3.PieArcDatum<number>, PieChartComponentProps, unknown> => {
  const Tooltip = d3
    .select(container.parentNode as d3.BaseType)
    .append("div")
    .style("opacity", 0)
    .attr(
      "class",
      "timeline-tooltip absolute bg-white border border-gray-300 rounded text-gray-600 opacity-75 p-3 mb-0 z-10 pointer-events-none"
    );

  const mouseover = function () {
    Tooltip.style("opacity", 1);
  };

  const mouseleave = function () {
    Tooltip.style("opacity", 0);
  };

  const mousemove = function (props: PieChartComponentProps) {
    const { xFormat, yAttr } = props.extraProps;
    return function (e: MouseEvent, d: d3.PieArcDatum<number>) {
      const [eventX, eventY] = d3.pointer(e);
      const html = `
        <span class="text-sm">
          <p>
            <span class="text-gray-600">${R.prop(yAttr, d)}: </span>
            <span class="text-gray-800 font-medium">${
              typeof xFormat === "function"
                ? xFormat(R.prop("value", d))
                : xFormat.format(R.prop("value", d))
            }</span>
          </p>
        </span>
      `;

      const { width, height } = container.getBoundingClientRect();

      Tooltip.html(html)
        .style(
          "left",
          `${eventX + container.parentElement.offsetLeft + width / 2}px`
        )
        .style(
          "top",
          `${eventY + container.parentElement.offsetTop + height / 2}px`
        );
    };
  };

  return { mouseover, mousemove, mouseleave };
};

const drawArc = (
  selection: d3.Selection<
    SVGPathElement,
    d3.PieArcDatum<number>,
    SVGGElement,
    Data[]
  >,
  props: PieChartProps,
  arc: d3.Arc<SVGGElement, d3.PieArcDatum<number>>,
  eventHandlers: EventHandlers<d3.PieArcDatum<number>, PieChartProps, unknown>
) => {
  const { mousemove, mouseover, mouseleave } = eventHandlers;
  const names = R.pluck("name", props.data);
  return selection
    .attr("d", arc)
    .on("mousemove", mousemove(props, undefined))
    .on("mouseover", function () {
      mouseover();
      d3.select(this)
        .attr("stroke", "black")
        .attr("stroke-width", "2px")
        .attr("stroke-linejoin", "round");
    })
    .on("mouseleave", function () {
      d3.select(this).attr("stroke", "").attr("stroke-width", "");
      mouseleave(undefined);
    })
    .attr("fill", (_d, i) =>
      props.extraProps.colorScale(R.prop(i, names) as string)
    );
};

type Data = Record<string, unknown>;

// This is the actual type of Data, need to figure out a way to do this
// polymorphically in the GraphContainer and we can re-enable.
// interface Data {
// 	name: string;
// 	value: number;
// }

interface D3Props {
  colorScale: d3.ScaleOrdinal<string, string>;
  xLabel: string;
  xAttr: string;
  xFormat: ((value: number) => string) | Intl.NumberFormat;
  yAttr: string;
  showLegend?: boolean;
  disableTransitions?: boolean;
}

type PieChartComponentProps = D3ComponentProps<Data[], D3Props>;
export type PieChartProps = Omit<
  D3ContainerProps<Data[], D3Props>,
  "width" | "height"
>;

export class D3PieChart extends BaseD3Component<
  Data[],
  PieChartComponentProps,
  unknown
> {
  arcs: d3.PieArcDatum<number>[];
  eventHandlers: EventHandlers<
    d3.PieArcDatum<number>,
    PieChartComponentProps,
    unknown
  >;
  height: number;
  width: number;

  constructor(container: HTMLElement, props: PieChartComponentProps) {
    super(container, props);
    this.height = MAX_HEIGHT;
    this.width = this.height + this.margin.right;
    this.init();
  }

  getBaseMargin() {
    return {
      top: 25,
      right: this.props.extraProps.showLegend ? 200 : 0,
      bottom: 0,
      left: 0,
    };
  }

  createScales() {
    return {
      legend: this.props.extraProps.colorScale,
    };
  }

  getGraphAxes() {
    return {};
  }

  getGraphElements() {
    return {
      arcs: (
        g: GroupSelection<Data[]>,
        { props }: { props: PieChartComponentProps }
      ) => {
        const outerRadius = Math.min(this.width, this.height) / 2;
        const arc = d3
          .arc<d3.PieArcDatum<number>>()
          .innerRadius(10)
          .padAngle(0.02)
          .padRadius(100)
          .cornerRadius(4)
          .outerRadius(outerRadius);
        const xData = g
          .selectAll("path")
          .property("__oldData__", function (d) {
            return d;
          })
          .data(this.arcs)
          .join("path");
        xData.join(
          (enter) =>
            drawArc(enter.append("path"), props, arc, this.eventHandlers),
          (
            update: d3.Selection<
              SVGPathElement,
              d3.PieArcDatum<number>,
              SVGGElement,
              Data[]
            >
          ) =>
            drawArc(update, props, arc, this.eventHandlers).call((update) => {
              if (!props.extraProps.disableTransitions) {
                update
                  .transition(Transitions.Standard())
                  .attrTween("d", function (d) {
                    const prevDataPoint = R.propOr(
                      null,
                      "__oldData__",
                      this
                    ) as any;
                    const start = !R.isNil(prevDataPoint)
                      ? {
                          startAngle: prevDataPoint.startAngle,
                          endAngle: prevDataPoint.endAngle,
                        }
                      : { startAngle: 0, endAngle: 0 };
                    const interpolate = d3.interpolate(start, d);
                    return (t) => arc(interpolate(t));
                  });
              }
            }),
          (exit) => exit.remove()
        );
      },
      legend: drawLegend({
        gutter: { left: 40, right: 20 },
        position: ({ gutter, ctx }) =>
          `translate(${ctx.width / 2 - gutter.left}, ${-ctx.height / 2})`,
      }),
    };
  }

  init() {
    this.svg
      .select("g.arcs")
      .attr("stroke-width", 1)
      .attr("stroke-linejoin", "round");
    this.eventHandlers = createTooltip(this.svg.node());

    Style.D3Text.Title(this.svg.select(".legend"));

    Style.D3Text.Title(
      this.svg
        .append("text")
        .attr("class", `x label`)
        .attr("fill", "currentColor")
        .attr("text-anchor", "middle")
        .attr("x", 0)
        .attr("y", -this.height / 2 - 10)
        .text(this.props.extraProps.xLabel)
    );
  }

  beforeUpdate() {
    const {
      data,
      width,
      extraProps: { xLabel, xAttr, yAttr },
    } = this.props;
    this.height = Math.min(MAX_HEIGHT, width - this.margin.right);
    this.width = width;
    this.svg
      .attr("width", this.width)
      .attr("height", this.height)
      .attr(
        "viewBox",
        `${-this.width / 2 + this.margin.right},${
          -this.height / 2 - this.margin.top
        },${this.width - this.margin.right},${this.height + this.margin.top}`
      )
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    this.svg
      .selectAll("text.x.label")
      .attr("x", 0)
      .attr("y", -this.height / 2 - 10)
      .text(xLabel);

    const values = R.pluck(xAttr, data) as number[];

    this.arcs = d3.pie().padAngle(0)(values) as d3.PieArcDatum<number>[];
    for (let i = 0; i < data.length; i++) {
      this.arcs[i] = R.set(
        R.lensProp(yAttr as keyof PieArcDatum<number>),
        R.path([i, yAttr], data),
        this.arcs[i]
      );
    }
  }
}

const createPieChart = (ref: HTMLElement, props: PieChartComponentProps) => {
  return new D3PieChart(ref, props);
};

export const PieChart: React.FC<PieChartProps> = (props) => {
  return (
    <div>
      <D3Container D3ComponentFactory={createPieChart} {...props} />
    </div>
  );
};
