import type { Data, Meta, Params } from "../types";

import * as d3 from "d3";
import * as R from "ramda";

import * as Style from "style";
import {
  BAR_HEIGHT,
  BENCHMARK_LABELS,
  SCATTER_PLOT_MULTIPLIER,
  SUBKEY_SEPARATOR,
  TICKS,
} from "../constant";
import { Transitions } from "components/graphs/util/transitions";
import { GroupSelection } from "lib/d3";
import { format } from "../../util";
import { getHydratedEmployeeLink } from "util/link";
import { drawRegions, RegionParams } from "./regions";
import { getBenchmarkAccessor, getBenchmarkFields } from "../props";
import { toHourlyValues } from "@milio/lib/util/math/util";

const X_MARGIN = 50;
const X_PADDING = 80;
const Y_MARGIN = 30;

interface PaybandPositionParams {
  data: Data[];
  params: Params | RegionParams;
}

const isNilOrZero = R.either(R.isNil, R.equals(0));

const getPaybandPosition = R.curry(
  ({ data, params }: PaybandPositionParams, d: Data) => {
    const { payAttr, paybandMinAttr, paybandMaxAttr } =
      params.props.extraProps.computed;
    const minIndex = d3.bisectLeft(
      R.map(R.prop(payAttr), data),
      R.prop(paybandMinAttr, d)
    );
    const maxIndex = d3.bisectLeft(
      R.map(R.prop(payAttr), data),
      R.prop(paybandMaxAttr, d)
    );

    return [minIndex, maxIndex];
  }
);

/**
 * Get the X position of the payband min line.
 */
export const getPaybandMinPosition = R.curry(
  (params: PaybandPositionParams, d: Data) => {
    const { x } = params.params.scales;
    const [minIndex] = getPaybandPosition(params, d);
    return x(minIndex - 0.5);
  }
);

/**
 * Get the X position of the payband max line.
 */
export const getPaybandMaxPosition = R.curry(
  (params: PaybandPositionParams, d: Data) => {
    const { x } = params.params.scales;
    const [, maxIndex] = getPaybandPosition(params, d);
    return x(maxIndex - 0.5);
  }
);

export const drawCircleCommon = (
  e: d3.Selection<d3.BaseType, [d: Data, i: number], d3.BaseType, unknown>,
  { props, scales }: Params
): d3.Selection<d3.BaseType, [d: Data, i: number], d3.BaseType, unknown> => {
  const { colorScale, getGroupColor } = props.extraProps;
  const { payAttr } = props.extraProps.computed;
  const { x, y } = scales;

  if (!props.extraProps.disableTransitions) {
    e.call((g) => g.transition(Transitions.Cubic()));
  }
  return e
    .attr("cx", (d) => {
      return x(d[1]);
    })
    .attr("cy", (d) => {
      return y(d[0][payAttr]);
    })
    .attr("r", 4)
    .attr("opacity", 1)
    .attr("fill", (d) => {
      return colorScale(getGroupColor(d[0], props));
    });
};

const updateScatterPlot = (
  g: GroupSelection<Meta>,
  { props, scales, ctx }: Params
) => {
  const { x: parentX, y: parentY } = scales;
  const {
    benchmarks,
    formatter,
    getYKey,
    paybands,
    hourly,
    payAttr,
    paybandMinAttr,
    paybandMaxAttr,
  } = props.extraProps.computed;
  const { xLabel } = props.extraProps;
  const benchmarkFields = getBenchmarkFields(props);
  g.each((datum: Meta, i: number, nodes: SVGGElement[]) => {
    if (R.isEmpty(datum.data)) return;
    const node = d3.select(nodes[i]);

    const [payMin, payMax] = d3.extent<number>(
      R.map(R.prop(payAttr), datum.data)
    );
    const paybandMin = d3.min<number>(
      R.map(R.prop(paybandMinAttr), datum.data)
    );
    const paybandMax = d3.max<number>(
      R.map(R.prop(paybandMaxAttr), datum.data)
    );

    const benchmarkMin = d3.min<number>(
      R.map(R.prop(R.head(benchmarkFields)), benchmarks)
    );
    const benchmarkMax = d3.max<number>(
      R.map(R.prop(R.last(benchmarkFields)), benchmarks)
    );

    const ROUNDING_FACTOR = hourly ? 0.5 : 500;
    const round = (d: number) =>
      Math.round(d / ROUNDING_FACTOR) * ROUNDING_FACTOR;
    const len = datum.data.length;
    const min = R.pipe(
      R.reject(R.isNil),
      R.reduce(R.min, Infinity),
      Number
    )([payMin, paybandMin, benchmarkMin]);
    const max = R.pipe(
      R.reject(R.isNil),
      R.reduce(R.max, -Infinity),
      Number
    )([payMax, paybandMax, benchmarkMax]);

    const parentX1 = R.last(parentX.range());
    const x = d3
      .scaleLinear<number, number>()
      .domain([-1, len])
      .range([X_MARGIN + X_PADDING, parentX1 - X_PADDING]);

    const yTop =
      parentY(`${datum.key}${SUBKEY_SEPARATOR}1`) -
      parentY.bandwidth() / 2 +
      Y_MARGIN * 2;
    const yBottom =
      yTop + BAR_HEIGHT * (SCATTER_PLOT_MULTIPLIER - 1) - Y_MARGIN * 3;

    const y = d3
      .scaleLinear()
      .domain([min * 0.9, max * 1.1])
      .range([yBottom, yTop])
      .nice();

    const xAxis = d3
      .axisBottom(x)
      .tickValues([])
      .tickSizeOuter(0) as unknown as (
      selection: d3.Selection<d3.BaseType, unknown, null, unknown>
    ) => void;

    const yAxis = d3
      .axisLeft(y)
      .tickFormat(format(formatter))
      .ticks(TICKS)
      .tickSizeOuter(0) as unknown as (
      selection: d3.Selection<d3.BaseType, unknown, null, unknown>
    ) => void;

    // Using xlabel on the y-axis here since the axes are inverted in the detail graph
    if (xLabel) {
      node.selectAll("g.subaxis.y text.label").remove();

      Style.D3Text.Title(
        node
          .select("g.subaxis.y")
          .append("text")
          .attr("class", `label`)
          .attr("text-anchor", "middle")
          .attr("fill", "currentColor")
          .attr("x", -X_MARGIN)
          .attr("y", (yBottom + yTop) / 2)
          .attr(
            "transform",
            `rotate(-90, -${X_MARGIN + 10}, ${(yBottom + yTop) / 2})`
          )
          .text(xLabel)
      );
    }

    Style.D3.Layout.Line(
      node
        .select("g.subaxis.y")
        .attr("transform", `translate(${X_MARGIN + X_PADDING},0)`)
        .call(yAxis)
    );

    Style.D3.Layout.Line(
      node
        .select("g.subaxis.x")
        .attr("transform", `translate(0,${yBottom})`)
        .call(xAxis)
    );

    Style.D3Text.Paragraph(node.selectAll(".tick text"));

    node
      .selectAll("rect")
      .data((d) => [d])
      .join("rect")
      .style("pointer-events", "all")
      .attr("fill", "none")
      .attr("x", x.range()[0])
      .attr("width", x.range()[1] - x.range()[0])
      .attr("y", yTop)
      .attr("height", yBottom - yTop)
      .on("mouseenter", ctx.eventHandlers.mouseover)
      .on("mousemove", ctx.eventHandlers.mousemove(props, { x, y }))
      .on("mouseleave", ctx.eventHandlers.mouseleave);

    const toBenchmark = getBenchmarkAccessor(getYKey);
    const benchmarkList = R.zip(
      benchmarkFields,
      R.props(
        benchmarkFields,
        R.pipe(
          R.head,
          toBenchmark,
          hourly
            ? R.mapObjIndexed((val) =>
                typeof val === "number" ? toHourlyValues(val) : val
              )
            : R.identity
        )(datum.data) as Record<string, unknown>
      )
    );

    const benchmarkGridlines = Style.D3.Layout.Line(
      node
        .selectAll("g.benchmark")
        .data(payAttr === "role_tenure" ? [] : benchmarkList)
        .join("g")
        .attr("class", "benchmark")
    );

    Style.D3Text.Paragraph(
      benchmarkGridlines
        .selectAll("line")
        .data((d) => {
          return R.isNil(d[1]) ? [] : [d];
        })
        .join("line")
        .attr("stroke", "currentColor")
        .attr("stroke-dasharray", "4")
        .attr("x1", x.range()[0])
        .attr("x2", x.range()[1])
        .attr("y1", (d) => {
          return y(d[1]);
        })
        .attr("y2", (d) => {
          return y(d[1]);
        })
    );

    Style.D3Text.Paragraph(
      benchmarkGridlines
        .selectAll("text.label")
        .data((d) => {
          return R.isNil(d[1]) ? [] : [d];
        })
        .join("text")
        .attr("class", "label")
        .attr("x", x.range()[1] + 3)
        .attr("y", (d) => {
          return y(d[1]) + 3;
        })
        .text((d) => {
          return `${BENCHMARK_LABELS[d[0]]} (${format(formatter)(
            round(d[1])
          )})`;
        })
    );
    const thisPayband = [
      R.head(
        paybands.filter((p) => {
          return getYKey(p) === getYKey(R.head(datum.data));
        })
      ),
    ];

    const params = { props, scales: { x, y, legend: R.always(null) }, ctx };

    const payBandMarkers = Style.D3.Layout.Line(
      node
        .selectAll("g.payband")
        .data(thisPayband)
        .join("g")
        .attr("class", "payband")
    );
    const getPaybandPos = getPaybandPosition({
      data: datum.data,
      params,
    });
    const getPaybandMinPos = getPaybandMinPosition({
      data: datum.data,
      params,
    });
    const getPaybandMaxPos = getPaybandMaxPosition({
      data: datum.data,
      params,
    });

    // Edge case. If all data points are above or below band, draw a special 'single point' payband.
    const getSinglePaybandPointData = (d: Data) => {
      const pos = getPaybandPos(d);
      return !isNilOrZero(R.prop(paybandMinAttr, d)) &&
        !isNilOrZero(R.prop(paybandMaxAttr, d)) &&
        pos[0] === pos[1]
        ? [d]
        : [];
    };

    const getPaybandMarkerData = (d: Data) => {
      return isNilOrZero(R.prop(paybandMinAttr, d)) ||
        isNilOrZero(R.prop(paybandMaxAttr, d)) ||
        !R.isEmpty(getSinglePaybandPointData(d))
        ? []
        : [d];
    };

    Style.D3Text.Paragraph(
      payBandMarkers
        .selectAll("line.min")
        .data(getPaybandMarkerData)
        .join("line")
        .attr("class", "min")
        .attr("stroke", "currentColor")
        .attr("stroke-dasharray", "4")
        .attr("x1", getPaybandMinPos)
        .attr("x2", getPaybandMinPos)
        .attr("y1", yTop)
        .attr("y2", yBottom)
    );

    Style.D3Text.Paragraph(
      payBandMarkers
        .selectAll("text.min.value")
        .data(getPaybandMarkerData)
        .join("text")
        .attr("class", "min value")
        .style("text-anchor", "middle")
        .attr("x", getPaybandMinPos)
        .attr("y", yBottom + 40)
        .text((d) => `${format(formatter)(round(d[paybandMinAttr]))}`)
    );

    Style.D3Text.Paragraph(
      payBandMarkers
        .selectAll("text.min.label")
        .data(getPaybandMarkerData)
        .join("text")
        .attr("class", "min label")
        .style("text-anchor", "middle")
        .attr("x", getPaybandMinPos)
        .attr("y", yBottom + 20)
        .text("Min")
    );

    Style.D3Text.Paragraph(
      payBandMarkers
        .selectAll("line.max")
        .data(getPaybandMarkerData)
        .join("line")
        .attr("class", "max")
        .attr("stroke", "currentColor")
        .attr("stroke-dasharray", "4")
        .attr("x1", getPaybandMaxPos)
        .attr("x2", getPaybandMaxPos)
        .attr("y1", yTop)
        .attr("y2", yBottom)
    );

    Style.D3Text.Paragraph(
      payBandMarkers
        .selectAll("text.max.label")
        .data(getPaybandMarkerData)
        .join("text")
        .attr("class", "max label")
        .style("text-anchor", "middle")
        .attr("x", getPaybandMaxPos)
        .attr("y", yBottom + 20)
        .text("Max")
    );

    Style.D3Text.Paragraph(
      payBandMarkers
        .selectAll("text.max.value")
        .data(getPaybandMarkerData)
        .join("text")
        .attr("class", "max value")
        .style("text-anchor", "middle")
        .attr("x", getPaybandMaxPos)
        .attr("y", yBottom + 40)
        .text((d) => `${format(formatter)(round(d[paybandMaxAttr]))}`)
    );

    Style.D3Text.Paragraph(
      payBandMarkers
        .selectAll("line.single")
        .data(getSinglePaybandPointData)
        .join("line")
        .attr("class", "single")
        .attr("stroke", "currentColor")
        .attr("stroke-dasharray", "4")
        .attr("x1", getPaybandMaxPos)
        .attr("x2", getPaybandMaxPos)
        .attr("y1", yTop)
        .attr("y2", yBottom)
    );

    Style.D3Text.Paragraph(
      payBandMarkers
        .selectAll("text.single.label")
        .data(getSinglePaybandPointData)
        .join("text")
        .attr("class", "single label")
        .style("text-anchor", "middle")
        .attr("x", getPaybandMaxPos)
        .attr("y", yBottom + 20)
        .text("Pay Band")
    );

    Style.D3Text.Paragraph(
      payBandMarkers
        .selectAll("text.single.value")
        .data(getSinglePaybandPointData)
        .join("text")
        .attr("class", "single value")
        .style("text-anchor", "middle")
        .attr("x", getPaybandMaxPos)
        .attr("y", yBottom + 40)
        .text(
          (d) =>
            `${format(formatter)(round(d[paybandMinAttr]))}-${format(formatter)(
              round(d[paybandMaxAttr])
            )}`
        )
    );

    node
      .selectAll("g.circles")
      .data(() => [datum])
      .join("g")
      .attr("class", "circles")
      .selectAll("a")
      .data(datum.data || [])
      .join("a")
      .attr("class", "cursor-pointer")
      .on("mouseenter", ctx.eventHandlers.mouseover)
      .attr("href", (d) => getHydratedEmployeeLink(d.employee_id))
      .attr("target", "_blank")
      .selectAll("circle")
      .data((d, i) => [[d, i]])
      .join("circle")
      .call(drawCircleCommon, {
        props,
        scales: { x, y: y as any, legend: null },
        ctx,
      });

    node.call(drawRegions, { props, scales: { x, y }, ctx });
  });

  return g;
};

const createScatterPlot = (g: GroupSelection<Meta>) => {
  Style.D3Text.Title(g.append("g").attr("class", "y subaxis"));
  Style.D3Text.Title(g.append("g").attr("class", "x subaxis"));
  return g;
};

/* Draw the scatterplot showing details for this subset of data. */
export const drawGroupDetail = (g: GroupSelection<unknown>, params: Params) => {
  const { props } = params;
  const { groupedData: data, expandedState } = props.extraProps.computed;
  const dotDetail = g.selectAll("g.detail-group").data(data);

  const groups = dotDetail
    .join("g")
    .attr("class", "detail-group")
    .attr("data-group", R.prop("key"));

  // the actual plots
  groups
    .selectAll("g.plot")
    .data((d) => [d])
    .join((enter) => enter.append("g").call(createScatterPlot))
    .attr("display", (d) => (expandedState[d.key] ? null : "none"))
    .attr("class", "plot")
    .call(updateScatterPlot, params);

  return dotDetail;
};
