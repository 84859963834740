import React from "react";
import * as R from "ramda";

import { Paragraph, Title } from "components/library";
import { createFormatter } from "lib/numberFormatter";
import { DECIMAL_PRECISION } from "util/format";

export interface PaybandTooltipData {
  currency: string;
  min: number;
  max: number;
  nextMin: number;
  prevMax: number;
  title: string;
}

export interface PaybandTooltipProps {
  payband: PaybandTooltipData;
}

export const PaybandTooltip: React.FC<PaybandTooltipProps> = ({ payband }) => {
  const currencyFormatter = React.useMemo(() => {
    return createFormatter({
      style: "currency",
      currency: payband.currency,
      digits: DECIMAL_PRECISION,
    });
  }, [payband]);

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
        <div className="grid">
          <span className="absolute inset-0" aria-hidden="true" />
          <Title>{payband.title}</Title>
          <Paragraph>Min: {currencyFormatter.format(payband.min)}</Paragraph>
          <Paragraph>Max: {currencyFormatter.format(payband.max)}</Paragraph>
          {!R.isNil(payband.prevMax) && (
            <Paragraph>
              Overlap with previous:{" "}
              {currencyFormatter.format(payband.prevMax - payband.min)}
            </Paragraph>
          )}
          {!R.isNil(payband.nextMin) && (
            <Paragraph>
              Overlap with next:{" "}
              {currencyFormatter.format(payband.max - payband.nextMin)}
            </Paragraph>
          )}
        </div>
      </div>
    </div>
  );
};
